<template>
  <div class="product-page-warper" id="productID">
    <div class="page-header flexbox">
      <navTab>
        <template #navRight>
          <svg-icon
            icon-class="batch-svg"
            class="filter-class"
            @click="batchIsShow = !batchIsShow"
          ></svg-icon>
          <svg-icon
            icon-class="filter-svg"
            class="filter-class"
            @click="searchVisible = true"
          ></svg-icon>
        </template>
      </navTab>
    </div>
    <!-- <div class="add-product-boxers">
      <div
        class="boxers"
        @click="$router.push({ path: '/appProductPackageIndex' })"
      >
        <p>
          <span>{{ language.productPackageLabel1 }}</span>
          <span>{{ language.productPackageLabel2 }}</span>
        </p>
        <van-icon name="arrow" />
      </div>
    </div> -->
    <div
      :class="['product-library-body', batchIsShow && 'product-library-batch']"
    >
      <div class="library-list-boxers">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishedText"
          :loading-text="loadingText"
          @load="onLoad"
        >
          <div class="list-item-boxers" v-if="projectDataList.length">
            <div
              :class="['list-item', item.sync && 'list-item-disabled']"
              v-for="(item, index) in projectDataList"
              :key="index"
            >
              <div
                class="checkBox"
                @click="onChangeCheckItem(item)"
                v-if="batchIsShow && !item.sync"
              >
                <svg-icon
                  icon-class="app-checked"
                  v-if="!item.checkedItem"
                ></svg-icon>
                <svg-icon
                  icon-class="app-checkbox"
                  v-if="item.checkedItem"
                ></svg-icon>
              </div>
              <div class="item-images imgFlex" @click="onChangeAdd(item)">
                <img v-lazy="imgUrl + item.image + thumbnail" />
              </div>
              <div class="item-info" @click="onChangeAdd(item)">
                <div class="info-title text2">{{ item.name }}</div>
                <div class="info-spec flexbox">
                  <span class="text2">{{ item.brand.name }}</span>
                  <span> {{ language.salesLabel }}{{ item.sales }}</span>
                </div>

                <div class="info-price">
                  <countTo
                    :startVal="0"
                    :endVal="item.unitAmount"
                    :duration="1200"
                    :decimals="2"
                    :separator="','"
                    :prefix="moneyIcon"
                  ></countTo>
                </div>
              </div>
              <div class="item-svg" @click="onChangeAdd(item)">
                <svg-icon v-if="!item.sync" icon-class="push-svg"></svg-icon>
              </div>
            </div>
          </div>
          <default-page v-else></default-page>
        </van-list>
      </div>
    </div>
    <div class="product-library-footer" v-if="batchIsShow">
      <p>{{ language.selectHint }}</p>
      <div class="footer-warper">
        <div class="footer-left" @click="onChangeCheckAll">
          <svg-icon icon-class="app-checked" v-if="!checkedAll"></svg-icon>
          <svg-icon icon-class="app-checkbox" v-if="checkedAll"></svg-icon>
          <span> {{ language.selectAll }}</span>
        </div>
        <div class="footer-right" @click="legalIsShow = true">
          {{ language.submitOptions }}
        </div>
      </div>
    </div>
    <template v-if="searchVisible">
      <van-popup
        v-model="searchVisible"
        position="top"
        @close="handlerClose"
        round
        class="popup-search"
      >
        <div class="search-popup-body">
          <div class="field-row">
            <van-field
              v-model="formObj.searchValue"
              :placeholder="language.searchPlace"
              clearable
            />
          </div>
          <div class="field-row flexbox">
            <!-- <van-field
              v-model="formObj.categoryName"
              :placeholder="language.classPlace"
              readonly
              right-icon="arrow-down"
              @click="onChangeSelect('class')"
            />
            <van-field
              v-model="formObj.brandName"
              :placeholder="language.brandPlace"
              readonly
              right-icon="arrow-down"
              @click="onChangeSelect('brand')"
            /> -->
          </div>
          <div class="search-options">
            <van-button @click="onChangeResult">
              {{ language.searchOptions1 }}
            </van-button>
            <van-button @click="handlerSubmitSearch">
              {{ language.searchOptions2 }}
            </van-button>
          </div>
        </div>
      </van-popup>
    </template>

    <van-popup
      v-if="classVisible"
      v-model="classVisible"
      position="bottom"
      class="van-popup-dialog"
    >
      <van-picker
        show-toolbar
        :confirm-button-text="language.dialogAffirm"
        :cancel-button-text="language.dialogClose"
        :columns="tmClassList"
        @confirm="onConfirmPicker"
        @cancel="onCancelPicker"
      />
    </van-popup>
    <van-popup v-model="legalIsShow" class="legal-popup">
      <div class="legal-body">
        <p class="title">{{ language.dialogHint }}</p>
        <div class="options">
          <span @click="legalIsShow = !legalIsShow">
            {{ language.dialogClose }}
          </span>
          <span @click="submitOptions">
            {{ language.dialogAffirm }}
          </span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import navTab from "@/layout/navTab.vue";
import productApi from "@/api/productApi.js";
import imgApi from "@/utils/projectConfig";
export default {
  name: "appProjectIndex",
  data() {
    return {
      language: this.$language(),
      thumbnail: this.$thumbnail,
      imgUrl: imgApi.imgUrl,
      finishedText: this.$language("messageLanguage").finishedText,
      loadingText: this.$language("messageLanguage").loadingText,
      loading: false,
      finished: false,
      moneyIcon: localStorage.getItem("moneyIcon"),
      searchVisible: false,
      categoryList: [], //分类数据集
      brandData: [], //品牌数据集
      formObj: {
        searchValue: "",
        shelves: "",
        brand: "",
        brandName: "",
        category: [],
        categoryName: "",
        page: 1,
        size: 20,
        total: 0,
      },
      tmClassList: [],
      classVisible: false,
      classOptionType: "",
      projectDataList: [],
      checkedAll: false,
      batchIsShow: false,
      checkLen: [],
      legalIsShow: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("productID").style["height"] = seeHeight + "px";
    });
  },

  methods: {
    onChangeCheckItem(record) {
      if (this.checkLen.length === 50) {
        if (!record.checkedItem) {
          this.$toast.fail(this.language.selectHint);
        } else {
          record.checkedItem = false;
        }
      } else {
        record.checkedItem = !record.checkedItem;
      }
      if (record.checkedItem) {
        this.checkLen.push(record.id);
      } else {
        this.checkLen = this.checkLen.filter((item) => {
          return item !== record.id;
        });
      }

      this.checkedAll = false;
      if (this.checkLen.length >= 1) {
        this.checkedAll = true;
      }
      this.$forceUpdate();
    },
    onChangeCheckAll(e) {
      this.checkedAll = !this.checkedAll;
      let list = [];
      this.checkLen = [];

      this.projectDataList.map((item, index) => {
        if (!item.sync && this.checkedAll) {
          list.length < 50 && list.push(item);
        }
      });
      this.projectDataList.map((item, index) => {
        list.map((listItem) => {
          if (listItem.id === item.id) {
            item.checkedItem = true;
            this.checkLen.push(item.id);
          }
        });
      });

      this.projectDataList.map((item) => {
        if (!this.checkedAll) item.checkedItem = false;
      });
      this.checkedAll = false;
      if (this.checkLen.length >= 1) {
        this.checkedAll = true;
      }
    },
    async submitOptions() {
      const { code, data, message } = await productApi.product_batch({
        ids: this.checkLen,
      });
      if (code === 200) {
        this.checkedAll = false;
        this.legalIsShow = false;
        this.checkLen = [];
        this.batchIsShow = false;
        this.$toast.success({
          forbidClick: true,
          duration: 1200,
          message: message,
        });
        setTimeout(() => {
          this.loading = true;
          this.finished = false;
          this.formObj.page = 1;
          this.projectDataList = [];
          this.getProductList();
        }, 2500);
      }
    },

    onChangeShelves(record, index) {
      this.shelvesActive = index;
    },
    handlerClose() {
      this.searchVisible = false;
    },
    onCancelPicker() {
      this.classVisible = false;
    },
    handlerSubmitSearch() {
      this.loading = true;
      this.finished = false;
      this.formObj.page = 1;
      this.projectDataList = [];
      this.searchVisible = false;
      this.getProductList();
    },
    // eslint-disable-next-line no-unused-vars
    onChangeAdd(record) {
      if (record.sync) {
        return false;
      }
      this.$router.push({
        path: "/appProductFirstIndex",
        query: { id: record.id, type: "add" },
      });
    },
    onLoad() {
      this.getProductList();
    },
    // 产品列表
    async getProductList() {
      let categoryId;
      if (this.formObj.category) {
        categoryId = this.formObj.category[this.formObj.category.length - 1];
      }
      const listParams = {
        name: this.formObj.searchValue,
        brandId: this.formObj.brand,
        categoryId: categoryId,
        page: this.formObj.page,
        size: this.formObj.size,
      };
      this.loading = true;
      const { code, data } = await productApi.getProductLibrary_interface(
        listParams
      );
      if (code == 200) {
        this.loading = false;
        data.list.map((item) => {
          item.checkedItem = false;
          if (item.state == 1) {
            item.stateCheck = true;
          }
          if (item.state == 2) {
            item.stateCheck = false;
          }
          item.unitAmount = Number((item.unitAmount / 100).toFixed(2));
        });
        this.projectDataList = this.projectDataList.concat(data.list);
        this.formObj.total = data.total;
        if (this.projectDataList.length >= this.formObj.total) {
          this.finished = true;
        } else {
          this.finished = false;
          this.formObj.page++;
        }
      }
    },

    onChangeSelect(key) {
      this.classOptionType = key;
      if (key === "class") {
        if (this.categoryList.length) {
          this.tmClassList = JSON.parse(JSON.stringify(this.categoryList));
          this.classVisible = true;
        } else {
          this.getCategoryData();
        }
      }
      if (key === "brand") {
        if (this.brandData.length) {
          this.tmClassList = JSON.parse(JSON.stringify(this.brandData));
          this.classVisible = true;
        } else {
          this.getBrandData();
        }
      }
    },
    onConfirmPicker(e, val) {
      this.classVisible = false;
      if (this.classOptionType === "class") {
        const id1 = val.length >= 1 && this.tmClassList[val[0]].id;
        const id2 =
          val.length >= 2 && this.tmClassList[val[0]].child[val[1]].id;
        const id3 =
          val.length >= 3 &&
          this.tmClassList[val[0]].child[val[1]].child[val[2]].id;
        this.formObj.category = [id1, id2, id3];
        this.formObj.categoryName = e.join("/");
      }
      if (this.classOptionType == "brand") {
        this.formObj.brand = e.id;
        this.formObj.brandName = e.name;
      }
    },
    async getBrandData() {
      const { code, data } = await productApi.getBrand_interface();
      if (code == 200) {
        data.list.map((item) => {
          item.value = item.id;
          item.text = item.name;
        });
        this.brandData = data.list;
        this.tmClassList = JSON.parse(JSON.stringify(this.brandData));
        this.classVisible = true;
      }
    },
    // 分类数据获取
    async getCategoryData() {
      const { code, data } = await productApi.getCategory_interface();
      if (code == 200) {
        noeTreeCall(data);
        this.categoryList = data;
        this.tmClassList = JSON.parse(JSON.stringify(this.categoryList));
        this.classVisible = true;
        function noeTreeCall(node) {
          for (const item of node) {
            item.value = item.id;
            item.text = item.name;
            item.children = item.child;
            if (item.children && item.children.length > 0) {
              noeTreeCall(item.children);
            }
          }
        }
      }
    },
    onChangeResult() {
      this.classOptionType = "";
      this.formObj.searchValue = "";
      this.formObj.brand = "";
      this.formObj.category = [];
      this.formObj.categoryName = "";
      this.formObj.brandName = "";
    },
  },
  components: { navTab },
};
</script>

<style lang="scss" scoped>
.product-page-warper {
  width: 100%;
  height: 100vh;
  background: #eff2f6;

  .page-header {
    svg {
      width: 38px !important;
      height: 38px !important;
      color: $color3;
    }
    svg {
      margin-right: 12px;
    }
  }
  .add-product-boxers {
    width: 100%;
    height: 100px;
    margin-top: 30px;
    padding: 0 30px;
    .boxers {
      height: 100%;
      background: #fff;
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
      font-size: 28px;
      color: #333;
      span:nth-child(1) {
        font-weight: 700;
      }
      span:nth-child(2) {
        font-size: 24px;
        color: #3d3d3d;
      }
    }
  }
  .product-library-body {
    width: 100%;
    height: calc(100% - 92px);
    padding: 30px;

    .library-list-boxers {
      width: 100%;
      height: 100%;
      overflow: auto;

      .van-list {
        .list-item-boxers {
          .list-item {
            padding: 24px;
            display: flex;
            width: 100%;
            margin-bottom: 40px;
            background: #fff;
            border-radius: 8px;
            position: relative;
            .checkBox {
              display: flex;
              align-items: center;
              padding-right: 12px;
              svg {
                width: 42px !important;
                height: 42px !important;
              }
            }

            .item-images {
              width: 160px;
              height: 160px;
              border-radius: 6px;
            }
          }

          .list-item-disabled {
            opacity: 0.5;
          }

          .item-info {
            width: 65%;
            padding-left: 20px;

            .info-title {
              width: 100%;
              font-size: 28px;
              color: #333;
              line-height: 32px;
              font-weight: 700;
            }

            .info-spec {
              font-size: 24px;
              color: #999;
              line-height: 2;
              margin-top: 10px;

              span:nth-child(1) {
                max-width: 65%;
                line-height: 1.5;
                margin-right: 10px;
              }
              span:nth-child(2) {
                max-width: 30%;
              }
            }

            .info-price {
              margin-top: 10px;
              font-size: 24px;
              color: $blur;
              line-height: 24px;
            }
          }

          .item-svg {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 40px !important;
            height: 8px !important;
          }
        }
      }
    }
  }
  .product-library-batch {
    height: calc(100% - 92px -  140px);
  }
  .product-library-footer {
    height: 140px;
    width: 100%;
    background: #fff;
    P:before {
      width: 10px;
      height: 10px;
      content: "*";
      color: red;
      font-size: 24px;
    }
    p {
      padding: 10px 30px;
      font-size: 22px;
    }
    .footer-warper {
      height: calc(100% - 50px);
      display: flex;
      align-items: center;
      border-top: 1px solid $blur;
    }
    .footer-left {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 30px;
      svg {
        width: 42px !important;
        height: 42px !important;
      }
      span {
        font-size: 28px;
        line-height: 22px;
        color: $blur;
      }
    }
    .footer-right {
      width: 50%;
      height: 100%;
      background: $blur;
      color: #fff;
      font-size: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .van-popup-dialog {
    width: 520px;
    max-height: 75%;
    height: 55%;
    border-radius: 16px;

    .popup-body {
      height: 100%;

      .popup-body-title {
        width: 100%;
        height: 94px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333;
        font-size: 32px;
        font-weight: 700;
        border-bottom: 1px solid #eaeaea;
      }

      .popup-body-list {
        width: 100%;
        height: calc(100% - 94px);
        overflow: scroll;

        .list-item {
          width: 100%;
          height: 94px;
          padding: 0 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #333;
          font-size: 32px;
          border-bottom: 1px solid #eaeaea;
          position: relative;

          span {
            opacity: 0.8;
          }

          .van-icon {
            position: absolute;
            right: 30px;
            top: calc(50% - 18px);

            color: $blur;
            font-size: 36px;
            text-align: right;
          }
        }

        .list-item:last-child {
          border-bottom: 0;
        }
      }
    }
  }

  .van-popup-dialog {
    width: 100%;
    max-height: 75%;
    height: 45%;
    border-radius: 16px;

    ::v-deep .van-picker {
      height: 100%;

      .van-picker__toolbar {
        font-size: 32px;
        font-weight: 600;

        .van-picker__confirm {
          color: $blur;
        }
      }
    }
  }

  .popup-search {
    max-height: 80%;
    min-height: 18%;
    padding: 40px 30px;
    overflow-y: scroll;
    border-radius: 0 0 56px 56px;

    .search-popup-body {
      .field-row {
        margin-bottom: 30px;

        ::v-deep .van-cell {
          border: 1px solid $blur;
          border-radius: 42px;
          padding: 0 30px;

          .van-field__control {
            padding: 12px 0;
            font-size: 24px;
            color: $color3 !important;
          }
        }
      }

      .field-row:nth-child(2) {
        justify-content: space-between;

        ::v-deep .van-cell {
          width: 48%;
        }
      }

      .search-options {
        margin-top: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .van-button {
          width: 45%;
          height: 75px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 28px;
          color: #fff;
          background: $blur;
          text-align: center;
          border-radius: 42px;
          border: 1px solid $blur;
        }

        .van-button:nth-child(1) {
          color: $blur;
          background: #fff;
          margin-right: 20px;
          border: 1px solid $blur;
        }
      }
    }
  }
  .legal-popup {
    border-radius: 8px;
    padding: 40px 30px;
    width: 420px;

    .legal-body {
      .title {
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        color: $color3;
        line-height: 1.5;
      }

      .options {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          display: inline-block;
          text-align: center;
          background: $blur;
          color: #fff;
          border-radius: 8px;
          font-size: 26px;
          width: 45%;
          padding: 20px 32px;
        }
        span:nth-child(1) {
          color: $blur;
          background: #fff;
          border: 1px solid $blur;
        }
      }
    }
  }
}
</style>
