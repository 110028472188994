<template>
  <div class="echCard-warper" id="main"></div>
</template>

<script>
import * as echarts from "echarts";
import { BarChart } from "echarts/charts";
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
} from "echarts/components";
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
// import homeApi from "@/api/homeApi";
// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
]);
import homeApi from "@/api/homeApi";
export default {
  name: "echCardIndex",
  data() {
    return {
      language: this.$language("appHomeIndex"),
      charts: "",
      moneyIcon: localStorage.getItem('moneyIcon'),
      chartsDataObj: {},
      flowData: [120, 110, 88, 1, 92, 1],
      orderNumber: 120,
      orderTitleLine: [100, 99, 77, 66, 44, 33],
      timeDate: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
    };
  },
  mounted() {
    this.getChartLineData();
    // this.$nextTick(() => {
    //   this.getEchInit();
    // });
  },
  methods: {
    async getChartLineData() {
      const { data, code } = await homeApi.getHomeLine_interface();
      if (code === 200 && (Object.keys(data).length || data.length)) {
        data.uniteAmountData = [];
        data.unitAmount.map((item) => {
          data.uniteAmountData.push(`${parseFloat((item / 100).toFixed(2))}`);
        });
        this.chartsDataObj = { ...data };
        this.$nextTick(() => {
          this.getEchInit();
        });
      } else {
        this.$nextTick(() => {
          this.getEchInit();
        });
      }
    },
    getEchInit() {
      let self = this;
      // 基于准备好的dom，初始化echarts实例
      self.charts = echarts.init(document.getElementById("main"), null);
      let option;
      option = {
        title: {},
        tooltip: {
          show: true,
          // 设置触发类型，默认为'item'，还可选值为 'axis', 'none'等
          trigger: "axis",
          formatter: function (params) {
            if (params.length === 1) {
              return `<div class='echarts-boxers'>
                          <p class='echarts-list'>
                            <span>${params[0].seriesName}</span>
                            <span>${params[0].data}</span>
                          </p>
                      </div>`;
            }
            if (params.length === 2) {
              return `<div class='echarts-boxers'>
                <p class='echarts-list'>
                            <span>${params[0].seriesName}</span>
                            <span>${params[0].data}</span>
                          </p>
                          <p class='echarts-list'>
                            <span>${params[1].seriesName}</span>
                            <span>${params[1].data}</span>
                          </p>
                      </div>`;
            }
            if (params.length === 3) {
              return `<div class='echarts-boxers'>
                          <p class='echarts-list'>
                            <span>${params[0].seriesName}</span>
                            <span>${params[0].data}</span>
                          </p>
                          <p class='echarts-list'>
                            <span>${params[1].seriesName}</span>
                            <span>${params[1].data}</span>
                          </p>
                          <p class='echarts-list'>
                            <span>${params[2].seriesName}</span>
                            <span>${params[2].data}</span>
                          </p>
                      </div>`;
            }
          },
          extraCssText:
            "min-width:30% !important; width:auto !important; height:auto !important; padding:10px 24px   !important;",
          // 设置浮动提示框位置，默认为鼠标指向处
          position: function (point, params, dom, rect, size) {
            var x = point[0];
            var y = point[1] - 200; // 调整y轴位置
            if (x > size.viewSize[0] / 2) {
              x -= 60; // 如果超出右边界则左移
            } else {
              x += 60; // 否则右移
            }
            return [x, y];
          },
          // 设置浮动提示框的样式
          textStyle: {
            backgroundColor: "rgba(0,0,0,.7)",
          },
        },
        legend: {
          data: [
            self.language.ech1Title,
            self.language.ech2Title,
            self.language.ech3Title,
          ],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            show: true, // 显示X轴直线
            lineStyle: {
              color: "#6d3580",
              width: 4,
            },
          },
          axisLabel: {
            fontWeight: 400,
            color: "#7f8b9c",
          },
          axisTick: {
            show: false, //是否显示有刻度
          },
          data: self.chartsDataObj.date,
        },
        yAxis: {
          scale: false,
          type: "value",
          min: 0, // Y轴最小值为0
          // max: 1, // Y轴最大值为100
          axisLine: {
            show: false, // 显示X轴直线
          },
          axisLabel: {
            fontWeight: 400,
            color: "#7f8b9c",
          },
        },

        series: [
          {
            name: self.language.echBuoy1Title,
            type: "line",
            smooth: true,
            stack: "Total",
            itemStyle: {
              width: 1,
              color: "#8883ff", // 设置折线的颜色为红色（十六进制）
            },
            data: self.chartsDataObj.uniteAmountData,
          },
          {
            name: self.language.echBuoy2Title,
            type: "line",
            smooth: true,
            stack: "Total",
            itemStyle: {
              width: 1,
              color: "#6d3580", // 设置折线的颜色为红色（十六进制）
            },
            data: self.chartsDataObj.order,
          },
          {
            name: self.language.echBuoy3Title,
            type: "line",
            smooth: true,
            stack: "Total",
            itemStyle: {
              width: 1,
              color: "#ffd769", // 设置折线的颜色为红色（十六进制）
            },
            data: self.chartsDataObj.flow,
          },
        ],
      };
      option && this.charts.setOption(option);
      window.addEventListener("resize", () => {
        this.charts.resize();
      });
    },
    // getEchInit() {
    //   let self = this;
    //   // 基于准备好的dom，初始化echarts实例
    //   self.charts = echarts.init(document.getElementById("main"), null);
    //   let option;
    //   option = {
    //     tooltip: {
    //       trigger: "axis",
    //       // formatter: "{params[0].seriesName}: {params[0].value}<br />{b1}: {c1}", //提示框浮层内容格式器，支持字符串模板和回调函数两种形式,模板变量有 {a}, {b}，{c}，{d}，{e}
    //       formatter: function (params) {
    //         console.log(params);
    //         if (params.length === 2) {
    //           return `<div class='echarts-boxers'>
    //           <p class='echarts-list'>
    //              <span>${params[0].seriesName}:</span>
    //              <span>${params[0].value}</span>
    //           </p>
    //           <p class='echarts-list'>
    //              <span>${params[1].seriesName}:</span>
    //              <span>${params[1].value}</span>
    //           </p>
    //         </div>`;
    //         } else if (params.length === 1) {
    //           return `<div class='echarts-boxers'>
    //           <p class='echarts-list'>
    //              <span>${params[0].seriesName}:</span>
    //              <span>${params[0].value}</span>
    //           </p>
    //         </div>`;
    //         }
    //       },
    //       // axisPointer: { type: "shadow" },
    //       extraCssText:
    //         "min-width:30% !important; width:auto !important; height:60px !important; padding:0 10px !important;",
    //       // position: function (point, params, dom, rect, size) {
    //       //   console.log(params);
    //       //   // 固定在顶部
    //       //   return [point[0], "0%"];
    //       // },
    //       // confine: true,
    //     },
    //     legend: {
    //       data: ["Email", "Union Ads"],
    //     },
    //     grid: {
    //       left: "3%",
    //       right: "4%",
    //       bottom: "3%",
    //       containLabel: true,
    //     },

    //     xAxis: {
    //       splitLine: {
    //         show: true,
    //         lineStyle: {
    //           type: "dashed",
    //         },
    //       },

    //       type: "category",
    //       boundaryGap: false,
    //       data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    //     },
    //     yAxis: {
    //       splitLine: {
    //         show: true,
    //         lineStyle: {
    //           type: "dashed",
    //         },
    //       },

    //       type: "value",
    //       show: true, //显示y轴线
    //       yAxisIndex: 1,
    //       min: 0, // Y轴最小值为0
    //       // max: 1, // Y轴最大值为100
    //       axisLine: {
    //         show: true, // 显示X轴直线
    //       },
    //       axisLabel: {
    //         fontWeight: 400,
    //         color: "#7f8b9c",
    //       },
    //       // boundaryGap: [0, "100%"],
    //     },
    //     series: [
    //       {
    //         name: "Email",
    //         type: "line",
    //         stack: "Ad",
    //         yAxisIndex: 0,
    //         data: [120, 132, 101, 134, 90, 230, 210],
    //         itemStyle: {
    //           normal: {
    //             color: "rgb(18,53,236)",
    //             lineStyle: {
    //               color: "rgb(18,53,236)",
    //             },
    //           },
    //         },
    //         areaStyle: {
    //           color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //             {
    //               offset: 0,
    //               color: "rgba(18,53,236,0.8)",
    //             },
    //             {
    //               offset: 1,
    //               color: "rgba(18,53,236,0.3)",
    //             },
    //           ]),
    //         },
    //       },
    //       {
    //         name: "Union Ads",
    //         type: "line",
    //         stack: "Ad",
    //         yAxisIndex: 0,
    //         data: [220, 182, 191, 234, 290, 330, 310],
    //         itemStyle: {
    //           normal: {
    //             color: "rgb(181,181,181)",
    //             lineStyle: {
    //               color: "rgb(181,181,181)",
    //             },
    //           },
    //         },
    //         areaStyle: {
    //           color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //             {
    //               offset: 0,
    //               color: "rgba(181,181,181,0.8)",
    //             },
    //             {
    //               offset: 1,
    //               color: "rgba(181,181,181,0.3)",
    //             },
    //           ]),
    //         },
    //       },
    //     ],
    //   };
    //   option && this.charts.setOption(option);
    //   window.addEventListener("resize", () => {
    //     this.charts.resize();
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.echCard-warper {
  margin: 10px 0;
  width: 100%;
  height: 100%;
}
#main {
  width: 100%;
  height: 100%;
  ::v-deep div {
    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
</style>
<style lang="scss">
.echarts-boxers {
  .echarts-list {
    display: flex;
    align-items: center;
    line-height: 1.5;
    span:nth-child(1) {
      font-size: 26px;
      font-weight: 600;
      color: $color3;
      margin-right: 6px;
    }
    span:nth-child(2) {
      margin-left: 4px;
      font-size: 24px;
      color: #8883ff;
    }
  }
  .echarts-list:nth-child(2) {
    display: flex;
    align-items: center;
    span:nth-child(2) {
      font-size: 23px;
      color: #7f8b9c;
    }
  }
  .echarts-list:nth-child(3) {
    display: flex;
    align-items: center;
    span:nth-child(2) {
      font-size: 23px;
      color: #ffd769;
    }
  }
}
</style>
