// 韩语
let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  "appWebSocketList": {
    navTitle: '메시지',
    IMErrorMessage: 'IM 연결에 실패했습니다. 다시 시도하려면 클릭하세요! ',
  },
  "appWebSocketDetails": {
    sendOption: '보내기',
    send: '보내다',
    fileFail: '사진을 업로드하고 형식을 지정해 주세요.',
    IMportError: '현재 연결되어 있지 않습니다. 다시 시도해 주세요! ',
    IMErrorMessage: 'IM 연결에 실패했습니다. 다시 시도해 주세요! ',
    retryBtn: '다시 시도',
  },
  "appLoginIndex": {
    navTitle: '로그인',
    projectName: '스토어 관리',
    formLabel1: '이메일',
    formLabel2: '비밀번호',
    placeholder: '입력하세요',
    hint: "계정이 없는 경우 ",
    clickRegister: '등록하려면 클릭하세요',
    forgetPass: '비밀번호를 잊어버렸습니다',
    optionBtn: '로그인',
    validateError: '입력을 완료해주세요! ',
  },
  "appForgetPassIndex": {
    navTitle: '비밀번호를 잊어버렸습니다',
    formLabel1: '로그인 메일함',
    formLabel2: '인증 코드',
    formLabel3: '새 비밀번호',
    formLabel4: '비밀번호 확인',
    formHint1: '로그인 이메일을 입력하세요',
    formHint2: '새 비밀번호를 입력하세요',
    formHint3: '인증코드를 입력해주세요',
    formHint4: '비밀번호를 두 번 입력하세요',
    formOption: '보내기',
    sendOption: '카운트다운:',
    optionsBtn: '확인',
  },
  'footer': {
    home: "스토어",
    project: '제품',
    info: '메시지',
    order: '주문',
    user: '내',
  },
  'setDrawer': {
    label1: '다국어 설정',
    label2: '스토어 등록',
    label3: '스토어 로그인',
    label4: '고객 서비스',
    label5: 'Android 애플리케이션 다운로드',
    label6: 'ios 애플리케이션 다운로드',
    optionsBtn: '취소',
  },
  'appHomeIndex': {
    accountLabel1: '판매',
    accountLabel2: '교통',
    cardLabel1: '데이터 개요',
    cardLabel2: '데이터 보고서',
    cardLabel3: '금액 개요',
    ech1Title: '총 판매량',
    ech2Title: '총 주문 수',
    ech3Title: '매장 트래픽',
    echBuoy1Title: '총 판매량',
    echBuoy2Title: '총 주문 수',
    echBuoy3Title: '매장 트래픽',
    dataCardTitle1: '매출',
    dataCardTitle2: '교통',
    dataCardTitle3: '총 주문 수',
    dataCardTitle4: '총 미결제 금액',
    dataCardDayTags: '오늘',
    dataYesterTags: "어제 데이터",
    dataYesterTags1: "확인되지 않은 주문 수량",
    cardMenusLabel1: '세금',
    cardMenusLabel2: '저장된 값',
    cardMenusLabel3: '철회',
    cardMenusLabel4: '초대 공유',
    cardMenusLabel5: '제품 목록',
    cardMenusLabel6: '주문 목록',
    cardMenusLabel7: '사업 보고서',
    taxPopupTitle: '현재 세금을 납부해야 합니다:',
    popupBtn: '가서 세금을 내세요',
    cardStateLabel1: '잔액',
    cardStateLabel2: '철회',
    cardStateLabel3: '소득',
    cardStateLabel4: '팀',
    cardSellHotTitle: '인기 판매 상위 10개 품목',
    salesLabel: '판매:',
  },
  'appSetLanguage': {
    navTitle: '다국어 설정',
    affirm: '확인하다',
  },
  "appServiceIndex": {
    navTitle: '고객 서비스',
    customerService: '독점적인 고객 서비스',
    helloTitle: '안녕하세요',
    welcomeTitle: '나는 당신의 것입니다<, 다음 채널을 통해 저에게 연락하실 수 있습니다',
    iconInformation: '상담을 위한 통신 소프트웨어를 선택해주세요',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
  },
  'appUserIndex': {
    blanceLabel: '균형',
    blanceOption1: '철회',
    blanceOption2: '값 저장',
    incomeLabel1: '누적 수입',
    incomeLabel2: '팀 수입',
    listLabel1: '매장 정보',
    listLabel2: '출금방법 설정',
    listLabel3: '펀드 기록',
    listLabel4: '사업 보고서',
    listLabel5: '할인 활동',
    listLabel7: '대출',
    listLabel8: '쇼핑몰로 이동',
    listLabel9: '애플리케이션 다운로드',
    listLabelLast: '종료',
  },
  'downloadAppIndex': {
    navTitle: '다운로드',
    downloadAndroidApp: "안드로이드 다운로드",
    downloadIosApp: "IOS 다운로드",
  },
  "appDiscountActivity": {
    navTitle: '할인 활동',
    popupLabel1: '금액',
    popupPlace1: '금액을 입력하세요',
    popupLabel2: '할인',
    popupPlace2: '할인액을 입력하세요',
    popupOption: '확인',
    listLabel1: '필요한 금액:',
    listLabel2: '할인율:',
    listLabel3: '할인 시간:',
    dialogTitle: "삭제 여부를 확인하세요!",
    dialogAffirm: '확인',
    dialogClose: "취소",
  },
  "appInviteIndex": {
    navTitle: '새 활동 초대',
    headerTitle: '친구를 초대하여 보상을 받으세요',
    copyOption: '복사',
    copySuccess: '복사 성공',
    inviteTitle: '총 초대 수',
    inviteLabel1: '초대된 사람 수:',
    inviteLabel2: '총 커미션:',
    ruleTitle: '초대 규칙',
    ruleHint1: ' 1. 초대한 사람은 프로모션 링크를 공유하여 등록을 완료하도록 친구를 초대할 수 있으며, 초대된 친구는 귀하와 연결됩니다.',
    ruleHint2: ' 2. 사용자를 초대하여 결제를 완료한 후 수익의 일부를 받을 수 있습니다.',
    ruleHint3: ' 3. 3단계 초대 보상만 지원되며 보상 비율은 <%, >%, @%; ',
  },
  "appInviteUserRecordIndex": {
    navTitle: '사용자 기록 초대',
    recordTitle1: '총 사용자 수',
    recordTitle2: '레벨 1',
    recordTitle3: '레벨 2',
    recordTitle4: '레벨 3',
    tabLabel1: '모두',
    tabLabel2: '레벨 1',
    tabLabel3: '두 번째 수준',
    tabLabel4: '레벨 3',
    listLabel1: '레벨:',
    listLabel2: '이익:',
  },
  "appInviteIncomeRecordIndex": {
    navTitle: '초대 수익 기록',
    recordTitle1: '총 수익',
    recordTitle2: '레벨 1',
    recordTitle3: '레벨 2',
    recordTitle4: '레벨 3',
    tabLabel1: '모두',
    tabLabel2: '레벨 1',
    tabLabel3: '두 번째 수준',
    tabLabel4: '레벨 3',
    listLabel1: '이익:',
    listLabel2: '비율:',
  },
  "appWithdrawIndex": {
    navTitle: '철회',
    currencyType1: '피아트 통화',
    currencyType2: '가상화폐',
    formLabel1: '통화 유형:',
    formPlace1: '통화 유형을 선택하세요',
    formLabel2: '통화 선택:',
    formPlace2: '통화 유형을 선택하세요',
    formLabel3: '출금 금액:',
    formPlace3: '인출 금액을 입력하세요',
    formLabel4: '출금 비밀번호:',
    formPlace4: '출금 비밀번호를 입력하세요:',
    balanceLabel: '현재 잔액:',
    amountLabel: '실제 도착:',
    optionsBtn: '제출',
    dialogClose: '취소',
    dialogAffirm: '확인',
    currencySelectError: '통화 유형을 선택하세요',
    currencyError: '통화를 선택하세요',
    affirmDialogDialogTitle1: '결제 비밀번호를 설정하지 않았습니다. 설정하시겠습니까? ',
    affirmDialogDialogTitle2: '출금 채널을 설정하지 않았습니다. 설정하시겠습니까? ',
    affirmDialogBtn2: '취소',
    affirmDialogBtn1: '확인',
  },
  'appSetWithdrawalIndex': {
    navTitle: '출금방법 설정',
    currencyType1: '피아트 통화',
    currencyType2: '가상화폐',
    formLabel1: '통화 유형:',
    formPlace1: '통화 유형을 선택하세요',
    formLabel2: '통화 선택:',
    formPlace2: '통화 유형을 선택하세요',
    addressLabel: '주소:',
    addressPlace: '가상화폐 주소를 입력해주세요',
    hintLabel: '중요 팁',
    hintValue: '자산 €만 전송하세요',
    optionsBtn: '제출',
    dialogClose: '취소',
    dialogAffirm: '확인',
    currencySelectError: '통화 유형을 선택하세요',
    currencyError: '통화를 선택하세요',
    currencyFail: '아직 열리지 않았습니다.',
  },
  //  提现设置-法定货币-BRL
  'brlLanguage': {
    formLabel1: '실명',
    formPlace1: '실명을 입력하세요',
    formLabel2: '이메일',
    formPlace2: '이메일을 입력하세요',
    formLabel3: '휴대폰번호',
    formPlace3: '휴대폰 번호를 입력하세요',
    formLabel4: 'PIX 유형',
    formPlace4: 'PIX 유형을 선택하세요',
    formLabel5: 'PIX 계정',
    formPlace5: 'PIX 계정 번호를 입력하세요',
  },
  //  提现设置-法定货币-EUR
  'eurLanguage': {
    formLabel1: '국가/지역',
    formPlace1: '국가/지역을 입력하세요',
    formLabel2: '이름',
    formPlace2: '이름을 입력하세요',
    formLabel3: '성',
    formPlace3: '성명을 입력하세요',
    formLabel4: '신속',
    formPlace4: 'swift를 입력해주세요',
    formLabel5: '은행 이름',
    formPlace5: '은행 이름을 입력하세요',
    formLabel6: '이반',
    formPlace6: 'iban을 입력해주세요',
    formLabel7: '휴대폰번호',
    formPlace7: '휴대폰 번호를 입력하세요',
  },
  //  提现设置-法定货币-USD
  'usdLanguage': {
    formLabel1: '국가/지역',
    formPlace1: '국가/지역을 입력하세요',
    formLabel2: '이름',
    formPlace2: '이름을 입력하세요',
    formLabel3: '성',
    formPlace3: '성명을 입력하세요',
    formLabel4: '신속',
    formPlace4: 'swift를 입력해주세요',
    formLabel5: '아',
    formPlace5: '아치를 입력하세요',
    formLabel6: '은행 이름',
    formPlace6: '은행 이름을 입력하세요',
    formLabel7: '은행 계좌',
    formPlace7: '은행 계좌를 입력하세요',
    formLabel8: '지방 또는 주',
    formPlace8: '도 또는 주를 입력하세요',
    formLabel9: '휴대폰번호',
    formPlace9: '휴대폰 번호를 입력하세요',
  },
  //  提现设置-法定货币-GBP
  'gbpLanguage': {
    formLabel1: '국가/지역',
    formPlace1: '국가/지역을 입력하세요',
    formLabel2: '이름',
    formPlace2: '이름을 입력하세요',
    formLabel3: '성',
    formPlace3: '성명을 입력하세요',
    formLabel4: '은행 코드',
    formPlace4: '은행 코드를 입력하세요',
    formLabel5: '은행 이름',
    formPlace5: '은행 이름을 입력하세요',
    formLabel6: '은행 계좌',
    formPlace6: '은행 계좌를 입력하세요',
    formLabel7: '휴대폰번호',
    formPlace7: '휴대폰 번호를 입력하세요',
  },
  //  提现设置-法定货币-JPY
  'jpyLanguage': {
    formLabel1: '국가/지역',
    formPlace1: '국가/지역을 입력하세요',
    formLabel2: '이름',
    formPlace2: '이름을 입력하세요',
    formLabel3: '성',
    formPlace3: '성명을 입력하세요',
    formLabel4: '젠긴',
    formPlace4: 'zengin을 입력하세요',
    formLabel5: '은행 이름',
    formPlace5: '은행 이름을 입력하세요',
    formLabel6: '은행 계좌',
    formPlace6: '은행 계좌를 입력하세요',
    formLabel7: '지방 또는 주',
    formPlace7: '도 또는 주를 입력하세요',
    formLabel8: '휴대폰번호',
    formPlace8: '휴대폰 번호를 입력하세요',
  },
  //  提现设置-法定货币-AUD
  'audLanguage': {
    formLabel1: '국가/지역',
    formPlace1: '국가/지역을 입력하세요',
    formLabel2: '이름',
    formPlace2: '이름을 입력하세요',
    formLabel3: '성',
    formPlace3: '성명을 입력하세요',
    formLabel4: 'BSB',
    formPlace4: 'BSB를 입력하세요',
    formLabel5: '은행 이름',
    formPlace5: '은행 이름을 입력하세요',
    formLabel6: '은행 계좌',
    formPlace6: '은행 계좌를 입력하세요',
    formLabel7: '지방 또는 주',
    formPlace7: '도 또는 주를 입력하세요',
    formLabel8: '휴대폰번호',
    formPlace8: '휴대폰 번호를 입력하세요',
  },
  //  提现设置-法定货币-krw
  'krwLanguage': {
    formLabel1: '이름',
    formPlace1: '이름을 입력하세요',
    formLabel2: '성',
    formPlace2: '성명을 입력하세요',
    formLabel3: '은행 이름',
    formPlace3: '은행 이름을 입력하세요',
    formLabel4: '은행 계좌',
    formPlace4: '은행 계좌를 입력하세요',
    formLabel5: '휴대폰번호',
    formPlace5: '휴대폰 번호를 입력하세요',
  }
  ,
  //  提现设置-法定货币-mxn
  'mxnLanguage': {
    formLabel1: '은행 이름',
    formPlace1: '은행 이름을 입력하세요',
    formLabel2: '계정',
    formPlace2: '계정을 입력하세요',
    formLabel3: 'clabe 계정',
    formPlace3: '클라베 계정을 입력하세요',
    formLabel4: '휴대폰번호',
    formPlace4: '휴대폰 번호를 입력하세요',
  },
  'appStatisticsReportIndex': {
    navTitle: '운영 보고서',
    cardLabel1: '총 판매량',
    cardLabel2: '총 구매 금액',
    cardLabel3: '총 수익',
    cardLabel4: '총 주문 수',
    listLabel1: '시간:',
    listLabel2: '주문:',
    listLabel3: '판매액:',
    listLabel4: '구매 금액:',
    profitLabel: '이익',
    searchDateTitle: '문서화 시간',
    searchIntervalTitle: '간격 시간',
    searchStartTimePlace: '시작 시간',
    searchEndTimePlace: '종료 시간',
    searchOptions1: '재설정',
    searchOptions2: '확인',
    searchDateAffirm: '확인',
    searchDateClose: '취소',
    searchIntervalLabel1: '모두',
    searchIntervalLabel2: '오늘',
    searchIntervalLabel3: '어제',
    searchIntervalLabel4: '이번 주',
    searchIntervalLabel5: '이번 달',
    searchIntervalLabel6: '올해',
    searchIntervalOptions: '취소',
  },
  "appAmountRechargeIndex": {
    navTitle: '저장된 값',
    currencyType1: '피아트 통화',
    currencyType2: '가상화폐',
    formLabel1: '통화 유형:',
    formPlace1: '통화 유형을 선택하세요',
    formLabel2: '통화 선택:',
    formPlace2: '통화 유형을 선택하세요',
    formLabel3: '저장된 금액:',
    formPlace3: '저장된 금액을 입력하세요',
    formLabel4: '충전 방법',
    formPlace4: '저장 방법을 선택하세요:',
    balanceLabel: '현재 잔액:',
    amountLabel: '실제 도착:',
    optionsBtn: '제출',
    dialogClose: '취소',
    dialogAffirm: '확인',
    currencySelectError: '통화 유형을 선택하세요',
    currencyError: '통화를 선택하세요',
    successCopy: '복사 성공',
    hintLabel: "중요 알림:",
    hintValue: '자산의 이체만 허용됩니다',
    legalHint: '고객센터에 연락하세요!'
  },
  'appShopInfoIndex': {
    navTitle: '상점 정보',
    navEditText: '편집',
    formLabel1: '이미지 저장',
    formLabel2: '상점 이름',
    formLabel3: '로그인 이메일',
    formLabel4: '초대 코드',
    formLabel5: '로그인 비밀번호',
    formLabel6: '결제 비밀번호',
    formLabel7: '설명 메시지',
    formLabel8: '배경 이미지 저장',
    formLabel9: '시장 배경 이미지',
    formLabel10: '인증서 정보',
    formHint1: '문서 앞부분',
    formHint2: '인증서 뒷면',
    fileFail: '사진을 업로드하고 형식을 지정하세요.',
    optionsBtn: '제출',
  },
  'appShopInfoEdit': {
    navTitle: '매장정보 편집자',
    formLabel1: '이미지 저장',
    formLabel2: '상점 이름',
    formLabel3: '로그인 이메일',
    formLabel4: '초대 코드',
    formLabel7: '설명 메시지',
    formLabel8: '배경 이미지 저장',
    formLabel9: '시장 배경 이미지',
    fileFail: '사진을 업로드하고 형식을 지정하세요.',
    optionsBtn: '제출',
  },
  'appChangeLoginPass': {
    navTitle: '로그인 비밀번호 변경',
    formLabel1: '이전 비밀번호',
    formLabel2: '새 비밀번호',
    formLabel3: '인증 코드',
    formHint1: '이전 비밀번호를 입력하세요',
    formHint2: '새 비밀번호를 입력하세요',
    formHint3: '인증코드를 입력해주세요',
    formOption: '보내기',
    sendOption: '카운트다운:',
    optionsBtn: '확인',
  },
  'appChangePayPass': {
    navTitle: '결제 비밀번호 변경',
    formLabel1: '결제 비밀번호',
    formLabel2: '인증 코드',
    formHint1: '결제 비밀번호를 입력하세요',
    formHint2: '인증코드를 입력해주세요',
    formOption: '보내기',
    sendOption: '카운트다운:',
    optionsBtn: '확인',
  },
  "appFundingRecordsIndex": {
    navTitle: '펀드 기록',
    searchRecordsTitle: '펀드 유형',
    searchDateTitle: '시간 선택',
    searchFundingLabel1: '모든 자금',
    searchFundingLabel2: '입금 유형',
    searchFundingLabel3: '출금 기록',
    searchOptions1: '재설정',
    searchOptions2: '확인',
    searchDateAffirm: '확인',
    searchDateClose: '취소',
    stareTimePlace: '시작 시간',
    endTimePlace: '종료 시간',
    typeAll: '모두',
  },
  "appProjectIndex": {
    navTitle: '제품 목록',
    searchPlace: '검색할 제품 이름을 입력하십시오',
    shelvesLabel: "상하 진열대",
    brandPlace: '브랜드를 선택하십시오',
    classPlace: '카테고리를 선택하십시오',
    searchOptions1: "재설정",
    searchOptions2: '확인',
    shelvesTag1: "모두",
    shelvesTag2: "진열대에서",
    shelvesTag3: "하차",
    salesLabel: '매출:',
    dialogAffirm: '확인',
    dialogClose: '취소',
    listLabel: '상하 진열대:',
  },
  "appProjectLibraryIndex": {
    navTitle: "제품 라이브러리",
    searchPlace: '검색하고 싶은 제품명을 입력해주세요',
    brandPlace: '브랜드를 선택하세요',
    classPlace: '카테고리를 선택하세요',
    searchOptions1: "재설정",
    searchOptions2: '확인',
    salesLabel: '판매:',
    dialogAffirm: '확인',
    dialogClose: '취소',
    productPackageLabel1: '패키지',
    productPackageLabel2: '(빠른 제품 추가)',

    selectAll: '다선',
    selectHint: '최대 50개 제품만 지원',
    submitOptions: '제출',
    dialogHint: '이 제품을 대량으로 출시하시겠습니까?',

  },
  'appProductPackageIndex': {
    navTitle: '패키지',
    listLabel1: '추천 별표:',
    listLabel2: '동기화 상태:',
    listLabel3: '제품 수량:',
    optionsBtn: '동기화',
    dialogHint: '이 패키지를 사용하시겠습니까?',
    dialogOptions1: '취소',
    dialogOptions2: '확인',
    messageToast: '제출, 제품 자료 동기화 중...',
  },
  'appProductPackageList': {
    navTitle: '패키지 상세 정보',
    salesLabel: '판매:',
  },
  "appProductFirstIndex": {
    navTitle: '제품 정보',
    nextStep: '다음 단계',
    label1: '제품 이름:',
    label2: '제품 브랜드:',
    label3: '제품 카테고리:',
    label4: '최소 구매 가격:',
    label5: '최소 판매 가격:',
    label6: '최소 판매 가격:',
    label7: '표지 이미지:',
    label8: '제품 사진:',

  },
  'appProductNextStepIndex': {
    navTitle: '제품 사양',
    previous: '이전 단계',
    navBtn: '제출',
    label1: '구매 가격:',
    placeHint: '입력하세요',
    label2: '가격:',
    label3: '시장 가격:',
    specLabel: '사양 속성:',
    optionBtn: "확인",
    validateMessage1: '원래 가격은 구매 가격보다 낮을 수 없습니다.',
    validateMessage2: '원래 가격은 현재 판매 가격보다 낮을 수 없습니다.',
    validateMessage3: '판매 가격은 구매 가격보다 낮을 수 없습니다.',
    validateMessage4: '판매 가격은 원래 가격보다 높을 수 없습니다.',
    shelvesTitle: '선반을 싣거나 내리시겠습니까? ',
    shelvesOptions1: '아니요',
    shelvesOptions2: '예',
  },
  "appOrderIndex": {
    tagsLabel1: '모두',
    orderTotalNumber: '총 주문 번호',
    listLabel1: '주문 날짜:',
    listLabel2: '총 판매량:',
    listLabel3: '제품 수량:',
    listLabel4: '주문 상태:',
    listLabel5: '결제 상태:',
    listHint: '구매',
    searchOptionBtn: '검색',
    searchPlace: '검색 내용을 입력하세요',
    searchDateStartPlace: '시작 시간',
    searchDateEndPlace: '종료 시간',
    searchDateAffirm: '확인',
    searchDateClose: '취소',
    searchTypePayTitle: '결제 상태',
    payTags1: '모두',
    payTags2: '결제 대기 중',
    payTags3: '유료',
    searchTypeOrderTitle: '주문 상태',
    searchOptions1: '재설정',
    searchOptions2: '확인',
    purDialogTitle: '모두 구매',
    purDialogLabel1: '구매 금액:',
    purDialogLabel2: '판매 금액:',
    purDialogLabel3: '이익 금액:',
    purOptions: "확인",
    purRowTitle: '구매',
    purRowLabel1: '구매 금액:',
    purRowLabel2: '판매 금액:',
    purRowLabel3: '이익 금액:',
  },
  "appOrderDetailIndex": {
    navTitle: '주문 세부정보',
    detailInfoLabel1: '주문 번호:',
    detailInfoLabel2: '주문 시간:',
    detailInfoLabel3: '결제 방법:',
    detailInfoLabel4: '주문 상태:',
    detailInfoLabel5: '결제 상태:',
    productLabel1: '제품 정보',
    productAttributesLabel: '제품 속성:',
    productAccountLabel1: '판매액:',
    productAccountLabel2: '구매 금액:',
    countInfoLabel1: '총 판매량:',
    countInfoLabel2: '총 구매 금액:',
    countInfoLabel3: '총 이익:',
    countInfoLabel4: '총 수량:',
    countInfoLabel5: '할인율:',
  },
  "appOrderDetailLogistics": {
    navTitle: '물류 주문',
    logisticsLabel: '주문',
    listLabel1: "이름:",
    listLabel2: "전화번호:",
    listLabel3: "우편번호:",
    listLabel4: "주소:",
  },
  "appOrderDetailRecord": {
    navTitle: '주문 기록',
    logisticsLabel: '주문',
    recordEnumLabel1: '고객이 주문을 생성했습니다.',
    recordEnumLabel2: '고객 결제 주문',
    recordEnumLabel3: '상점 구매 주문서',
    recordEnumLabel4: '주문 배송 시작',
    recordEnumLabel5: '사용자가 영수증을 확인합니다',
    recordEnumLabel6: '시스템 상환 도착',
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: '버전 업그레이드를 확인해주세요. ',
    loadingTitle: '다운로드 중...',
    systemUpgrade: '시스템 업그레이드',
    upgradeImmediately: '확인',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "로드 중",
    finishedText: "없음",
    loadingText: "로드 중",
    nullText: '아직 데이터가 없습니다',
    loadingFinished: "로드가 완료되었습니다",
    listNullText: '더 이상은 없습니다',
    pullIngText: '새로고침하려면 아래로 당기세요',
    loosingText: '새로 고침을 해제하세요',
    pullingSuccess: '풀다운 로딩 성공',
    webSocketSuccess: '새 메시지가 있습니다.',
    imLinkSuccess: 'IM 서비스 연결에 성공했습니다! ',
    imNonsupport: '이 브라우저는 웹소켓을 지원하지 않습니다',
    imLinkLoading: 'IM이 연결 중입니다. 잠시 기다려 주세요...',
    imLinkErrorLoading: 'IM 연결에 실패했습니다. 페이지를 새로 고치거나 개발자에게 문의하세요! ',
    dialogHint: '이미 계정이 있습니까?',
    dialogClose: '등록',
    dialogAffirm: '로그인',
  },
};
export default obj;
