// 法语

let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  "appWebSocketList": {
    navTitle: 'Message',
    IMErrorMessage: 'La connexion IM a échoué, cliquez pour réessayer ! ',
  },
  "appWebSocketDetails": {
    sendOption: 'Envoyer',
    send: 'Envoyer',
    fileFail: 'Veuillez télécharger et formater les images',
    IMportError: ' Actuellement non connecté, veuillez réessayer ! ',
    IMErrorMessage: 'La connexion IM a échoué, veuillez réessayer ! ',
    retryBtn: 'Réessayer',
  },
  "appLoginIndex": {
    navTitle: 'Connexion',
    projectName: 'Gérer la boutique',
    formLabel1: 'E-mail',
    formLabel2: 'mot de passe',
    placeholder: 'Veuillez entrer',
    hint: "Si vous n'avez pas de compte",
    clickRegister: 'Cliquez pour vous inscrire',
    forgetPass: 'mot de passe oublié',
    optionBtn: 'Connexion',
    validateError: 'Veuillez compléter la saisie ! ',
  },
  "appForgetPassIndex": {
    navTitle: 'Mot de passe oublié',
    formLabel1: 'Boîte aux lettres de connexion',
    formLabel2: 'Code de vérification',
    formLabel3: 'Nouveau mot de passe',
    formLabel4: 'Confirmer le mot de passe',
    formHint1: 'Veuillez saisir votre email de connexion',
    formHint2: 'Veuillez saisir un nouveau mot de passe',
    formHint3: 'Veuillez saisir le code de vérification',
    formHint4: 'Veuillez saisir votre mot de passe deux fois',
    formOption: 'Envoyer',
    sendOption: 'Compte à rebours :',
    optionsBtn: 'Confirmer',
  },
  'footer': {
    home: 'Magasin',
    project: 'Produit',
    info: 'Message',
    order: 'Commander',
    user: 'Mon',
  },
  'setDrawer': {
    label1: 'Paramètres multilingues',
    label2: 'Enregistrement du magasin',
    label3: 'Connexion au magasin',
    label4: 'Service Client',
    label5: "Télécharger l'application Android",
    label6: "Télécharger l'application iOS",
    optionsBtn: 'Annuler',
  },
  'appHomeIndex': {
    accountLabel1: 'Ventes',
    accountLabel2: 'trafic',
    cardLabel1: 'Aperçu des données',
    cardLabel2: 'Rapport de données',
    cardLabel3: 'Aperçu du montant',
    ech1Title: 'Ventes totales',
    ech2Title: 'Nombre total de commandes',
    ech3Title: 'Trafic du magasin',
    echBuoy1Title: 'Ventes totales',
    echBuoy2Title: 'Nombre total de commandes',
    echBuoy3Title: 'Trafic du magasin',
    dataCardTitle1: 'Ventes',
    dataCardTitle2: 'Trafic',
    dataCardTitle3: 'Nombre total de commandes',
    dataCardTitle4: 'Montant total impayé',
    dataCardDayTags: "Aujourd'hui",
    dataYesterTags: "Données d'hier",
    dataYesterTags1: "Quantité de commandes non contrôlées",
    cardMenusLabel1: 'Taxe',
    cardMenusLabel2: 'Valeur stockée',
    cardMenusLabel3: 'Retrait',
    cardMenusLabel4: "Partager l'invitation",
    cardMenusLabel5: 'Liste de produits',
    cardMenusLabel6: 'Liste des commandes',
    cardMenusLabel7: "Rapport d'activité",
    taxPopupTitle: 'Je dois actuellement payer des impôts :',
    popupBtn: 'Allez payer des impôts',
    cardStateLabel1: 'Solde',
    cardStateLabel2: 'Retrait',
    cardStateLabel3: 'Revenu',
    cardStateLabel4: 'Équipe',
    cardSellHotTitle: "Top 10 des articles les plus vendus",
    salesLabel: 'Ventes :',
  },
  'appSetLanguage': {
    navTitle: 'Paramètres multilingues',
    affirm: 'confirmer',
  },
  "appServiceIndex": {
    navTitle: 'Service client',
    customerService: 'Service client exclusif',
    helloTitle: 'Bonjour',
    welcomeTitle: 'Je suis à toi<, vous pouvez me contacter via les canaux suivants',
    iconInformation: 'Veuillez sélectionner le logiciel de communication à consulter',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
  },
  'appUserIndex': {
    blanceLabel: 'Balance',
    blanceOption1: 'Withdraw',
    blanceOption2: 'Store value',
    incomeLabel1: 'Cumulative income',
    incomeLabel2: 'Team income',
    listLabel1: 'Store information',
    listLabel2: 'Withdrawal method settings',
    listLabel3: 'Fund Record',
    listLabel4: 'Business Report',
    listLabel5: 'Discount activity',
    listLabel7: 'Loan',
    listLabel8: 'Go to the mall',
    listLabel9: 'Download application',
    listLabelLast: 'Exit',
  },
  'downloadAppIndex': {
    navTitle: 'Télécharger',
    downloadAndroidApp: "Téléchargement Android",
    downloadIosApp: "Téléchargement IOS",
  },
  "appDiscountActivity": {
    navTitle: 'Activité de réduction',
    popupLabel1: 'Montant',
    popupPlace1: 'Veuillez saisir le montant',
    popupLabel2: 'Remise',
    popupPlace2: 'Veuillez saisir la réduction',
    popupOption: 'Confirmer',
    listLabel1: 'Montant requis :',
    listLabel2: 'Taux de remise :',
    listLabel3: 'Durée de remise :',
    dialogTitle: "Confirmez s'il faut supprimer !",
    dialogAffirm: 'Confirmer',
    dialogClose: "Annuler",
  },
  "appInviteIndex": {
    navTitle: 'Inviter de nouvelles activités',
    headerTitle: 'Invitez des amis à gagner des récompenses',
    copyOption: 'copier',
    copySuccess: 'Copie réussie',
    inviteTitle: "Nombre total d'invitations",
    inviteLabel1: 'Nombre de personnes invitées :',
    inviteLabel2: 'Commission totale :',
    ruleTitle: "Règle d'invitation",
    ruleHint1: " 1. L'invitant peut inviter des amis à terminer l'inscription en partageant le lien de promotion, et les amis invités seront associés à vous ;",
    ruleHint2: ' 2. Après avoir invité les utilisateurs à effectuer le paiement, vous pouvez obtenir une part de leurs bénéfices ;',
    ruleHint3: " 3. Seules les récompenses d'invitation de troisième niveau sont prises en charge et le ratio de récompense est: <%, >%, @% ; ",
  },
  "appInviteUserRecordIndex": {
    navTitle: 'Inviter un enregistrement utilisateur',
    recordTitle1: "Nombre total d'utilisateurs",
    recordTitle2: 'Niveau 1',
    recordTitle3: 'Niveau 2',
    recordTitle4: 'Niveau 3',
    tabLabel1: 'Tous',
    tabLabel2: 'Niveau 1',
    tabLabel3: 'Deuxième niveau',
    tabLabel4: 'Niveau 3',
    listLabel1: 'Niveau :',
    listLabel2: 'Bénéfice :',
  },
  "appInviteIncomeRecordIndex": {
    navTitle: 'Enregistrement des revenus des invitations',
    recordTitle1: 'Revenu total',
    recordTitle2: 'Niveau 1',
    recordTitle3: 'Niveau 2',
    recordTitle4: 'Niveau 3',
    tabLabel1: 'Tous',
    tabLabel2: 'Niveau 1',
    tabLabel3: 'Deuxième niveau',
    tabLabel4: 'Niveau 3',
    listLabel1: 'Bénéfice :',
    listLabel2: 'Proportion :',
  },
  "appWithdrawIndex": {
    navTitle: 'Retrait',
    currencyType1: 'monnaie Fiat',
    currencyType2: 'Monnaie virtuelle',
    formLabel1: 'Type de devise :',
    formPlace1: 'Veuillez sélectionner le type de devise',
    formLabel2: 'Sélectionnez la devise :',
    formPlace2: 'Veuillez sélectionner le type de devise',
    formLabel3: 'Montant du retrait :',
    formPlace3: 'Veuillez saisir le montant du retrait',
    formLabel4: 'Mot de passe de retrait :',
    formPlace4: 'Veuillez saisir le mot de passe de retrait :',
    balanceLabel: 'Solde actuel :',
    amountLabel: 'Arrivée réelle :',
    optionsBtn: 'Soumettre',
    dialogClose: 'Annuler',
    dialogAffirm: 'Confirmer',
    currencySelectError: 'Veuillez sélectionner le type de devise',
    currencyError: "Veuillez sélectionner la devise",
    affirmDialogDialogTitle1: "Vous n'avez pas défini de mot de passe de paiement.Voulez - vous le configurer? ",
    affirmDialogDialogTitle2: "Vous n'avez pas défini le canal de retrait, souhaitez- vous le définir? ",
    affirmDialogBtn2: 'Annuler',
    affirmDialogBtn1: 'Confirmer',
  },
  'appSetWithdrawalIndex': {
    navTitle: 'Paramètre de la méthode de retrait',
    currencyType1: 'monnaie Fiat',
    currencyType2: 'Monnaie virtuelle',
    formLabel1: 'Type de devise :',
    formPlace1: 'Veuillez sélectionner le type de devise',
    formLabel2: 'Sélectionnez la devise :',
    formPlace2: 'Veuillez sélectionner le type de devise',
    addressLabel: 'Adresse :',
    addressPlace: "Veuillez saisir l'adresse de la monnaie virtuelle",
    hintLabel: 'Conseil important',
    hintValue: 'Transférer uniquement les actifs ¥',
    optionsBtn: 'Soumettre',
    dialogClose: 'Annuler',
    dialogAffirm: 'Confirmer',
    currencySelectError: 'Veuillez sélectionner le type de devise',
    currencyError: "Veuillez sélectionner la devise",
    currencyFail: 'Pas encore ouvert',
  },
  //  提现设置-法定货币-BRL
  'brlLanguage': {
    formLabel1: 'vrai nom',
    formPlace1: 'Veuillez entrer votre vrai nom',
    formLabel2: 'E-mail',
    formPlace2: 'Veuillez saisir votre email',
    formLabel3: 'Numéro de téléphone portable',
    formPlace3: 'Veuillez saisir votre numéro de téléphone portable',
    formLabel4: 'Type PIX',
    formPlace4: 'Veuillez sélectionner le type PIX',
    formLabel5: 'Compte PIX',
    formPlace5: 'Veuillez saisir votre numéro de compte PIX',
  },
  //  提现设置-法定货币-EUR
  'eurLanguage': {
    formLabel1: 'Pays/Région',
    formPlace1: 'Veuillez entrer un pays/une région',
    formLabel2: 'nom',
    formPlace2: 'Veuillez entrer un nom',
    formLabel3: 'Nom de famille',
    formPlace3: 'Veuillez entrer votre nom de famille',
    formLabel4: 'rapide',
    formPlace4: 'Veuillez saisir Swift',
    formLabel5: 'Nom de la banque',
    formPlace5: 'Veuillez saisir le nom de la banque',
    formLabel6: 'iban',
    formPlace6: 'Veuillez saisir iban',
    formLabel7: 'Numéro de téléphone portable',
    formPlace7: 'Veuillez saisir votre numéro de téléphone portable',
  },
  //  提现设置-法定货币-USD
  'usdLanguage': {
    formLabel1: 'Pays/Région',
    formPlace1: 'Veuillez entrer un pays/une région',
    formLabel2: 'nom',
    formPlace2: 'Veuillez entrer un nom',
    formLabel3: 'Nom de famille',
    formPlace3: 'Veuillez entrer votre nom de famille',
    formLabel4: 'rapide',
    formPlace4: 'Veuillez saisir Swift',
    formLabel5: 'chaque',
    formPlace5: 'Veuillez saisir ach',
    formLabel6: 'Nom de la banque',
    formPlace6: 'Veuillez saisir le nom de la banque',
    formLabel7: 'Compte bancaire',
    formPlace7: 'Veuillez saisir un compte bancaire',
    formLabel8: 'Province ou état',
    formPlace8: "Veuillez entrer la province ou l'état",
    formLabel9: 'Numéro de téléphone portable',
    formPlace9: 'Veuillez saisir votre numéro de téléphone portable',
  },
  //  提现设置-法定货币-GBP
  'gbpLanguage': {
    formLabel1: 'Pays/Région',
    formPlace1: 'Veuillez entrer un pays/une région',
    formLabel2: 'nom',
    formPlace2: 'Veuillez entrer un nom',
    formLabel3: 'Nom de famille',
    formPlace3: 'Veuillez entrer votre nom de famille',
    formLabel4: 'Code banque',
    formPlace4: 'Veuillez saisir le code banque',
    formLabel5: 'Nom de la banque',
    formPlace5: 'Veuillez saisir le nom de la banque',
    formLabel6: 'Compte bancaire',
    formPlace6: 'Veuillez saisir un compte bancaire',
    formLabel7: 'Numéro de téléphone portable',
    formPlace7: 'Veuillez saisir votre numéro de téléphone portable',
  },
  //  提现设置-法定货币-JPY
  'jpyLanguage': {
    formLabel1: 'Pays/Région',
    formPlace1: 'Veuillez entrer un pays/une région',
    formLabel2: 'nom',
    formPlace2: 'Veuillez entrer un nom',
    formLabel3: 'Nom de famille',
    formPlace3: 'Veuillez entrer votre nom de famille',
    formLabel4: 'zengin',
    formPlace4: 'Veuillez saisir zengin',
    formLabel5: 'Nom de la banque',
    formPlace5: 'Veuillez saisir le nom de la banque',
    formLabel6: 'Compte bancaire',
    formPlace6: 'Veuillez saisir un compte bancaire',
    formLabel7: 'Province ou état',
    formPlace7: "Veuillez entrer la province ou l'état",
    formLabel8: 'Numéro de téléphone portable',
    formPlace8: 'Veuillez saisir votre numéro de téléphone portable',
  },
  //  提现设置-法定货币-AUD
  'audLanguage': {
    formLabel1: 'Pays/Région',
    formPlace1: 'Veuillez entrer un pays/une région',
    formLabel2: 'nom',
    formPlace2: 'Veuillez entrer un nom',
    formLabel3: 'Nom de famille',
    formPlace3: 'Veuillez entrer votre nom de famille',
    formLabel4: 'BSB',
    formPlace4: 'Veuillez saisir BSB',
    formLabel5: 'Nom de la banque',
    formPlace5: 'Veuillez saisir le nom de la banque',
    formLabel6: 'Compte bancaire',
    formPlace6: 'Veuillez saisir un compte bancaire',
    formLabel7: 'Province ou état',
    formPlace7: "Veuillez entrer la province ou l'état",
    formLabel8: 'Numéro de téléphone portable',
    formPlace8: 'Veuillez saisir votre numéro de téléphone portable',
  },
  //  提现设置-法定货币-krw
  'krwLanguage': {
    formLabel1: 'nom',
    formPlace1: 'Veuillez entrer un nom',
    formLabel2: 'Nom de famille',
    formPlace2: 'Veuillez entrer votre nom de famille',
    formLabel3: 'Nom de la banque',
    formPlace3: 'Veuillez saisir le nom de la banque',
    formLabel4: 'Compte bancaire',
    formPlace4: 'Veuillez saisir un compte bancaire',
    formLabel5: 'Numéro de téléphone portable',
    formPlace5: 'Veuillez saisir votre numéro de téléphone portable',
  }
  ,
  //  提现设置-法定货币-mxn
  'mxnLanguage': {
    formLabel1: 'Nom de la banque',
    formPlace1: 'Veuillez saisir le nom de la banque',
    formLabel2: 'Compte',
    formPlace2: 'Veuillez entrer votre compte',
    formLabel3: 'compte clab',
    formPlace3: 'Veuillez saisir votre compte Clabe',
    formLabel4: 'Numéro de téléphone portable',
    formPlace4: 'Veuillez saisir votre numéro de téléphone portable',
  },
  'appStatisticsReportIndex': {
    navTitle: "Rapport d'exploitation",
    cardLabel1: 'Ventes totales',
    cardLabel2: "Montant total de l'achat",
    cardLabel3: 'Bénéfice total',
    cardLabel4: 'Nombre total de commandes',
    listLabel1: 'Heure :',
    listLabel2: 'Ordre :',
    listLabel3: 'Ventes :',
    listLabel4: "Montant de l'achat:",
    profitLabel: 'profit',
    searchDateTitle: 'Heure du document',
    searchIntervalTitle: "Durée d'intervalle",
    searchStartTimePlace: 'Heure de début',
    searchEndTimePlace: 'Heure de fin',
    searchOptions1: 'Réinitialiser',
    searchOptions2: 'Confirmer',
    searchDateAffirm: 'confirmer',
    searchDateClose: 'Annuler',
    searchIntervalLabel1: 'Tous',
    searchIntervalLabel2: "Aujourd'hui",
    searchIntervalLabel3: 'Hier',
    searchIntervalLabel4: 'Cette semaine',
    searchIntervalLabel5: 'Ce mois-ci',
    searchIntervalLabel6: 'Cette année',
    searchIntervalOptions: 'Annuler',
  },
  "appAmountRechargeIndex": {
    navTitle: 'Valeur stockée',
    currencyType1: 'monnaie Fiat',
    currencyType2: 'Monnaie virtuelle',
    formLabel1: 'Type de devise :',
    formPlace1: 'Veuillez sélectionner le type de devise',
    formLabel2: 'Sélectionnez la devise :',
    formPlace2: 'Veuillez sélectionner le type de devise',
    formLabel3: 'Montant de la valeur stockée :',
    formPlace3: 'Veuillez saisir le montant de la valeur stockée',
    formLabel4: 'Méthode de recharge',
    formPlace4: 'Veuillez sélectionner la méthode de stockage :',
    balanceLabel: 'Solde actuel :',
    amountLabel: 'Arrivée réelle :',
    optionsBtn: 'Soumettre',
    dialogClose: 'Annuler',
    dialogAffirm: 'Confirmer',
    currencySelectError: 'Veuillez sélectionner le type de devise',
    currencyError: "Veuillez sélectionner la devise",
    successCopy: 'Copier avec succès',
    hintLabel: "Rappel important :",
    hintValue: "Seul le transfert d'actifs ¥ est autorisé",
    legalHint: 'Veuillez contacter le service clientèle!'
  },
  'appShopInfoIndex': {
    navTitle: 'Informations sur le magasin',
    navEditText: 'Modifier',
    formLabel1: "Stocker l'image",
    formLabel2: 'nom du magasin',
    formLabel3: 'E-mail de connexion',
    formLabel4: "code d'invitation",
    formLabel5: 'Mot de passe de connexion',
    formLabel6: 'Mot de passe de paiement',
    formLabel7: 'Message de description',
    formLabel8: "Enregistrer l'image de fond",
    formLabel9: "Image d'arrière - plan du marché",
    formLabel10: 'Informations sur le certificat',
    formHint1: 'Recto du document',
    formHint2: 'Le verso du certificat',
    fileFail: 'Veuillez télécharger les images et le format',
    optionsBtn: 'Soumettre',
  },
  'appShopInfoEdit': {
    navTitle: "Éditeur d'informations sur le magasin",
    formLabel1: "Stocker l'image",
    formLabel2: 'nom du magasin',
    formLabel3: 'E-mail de connexion',
    formLabel4: "code d'invitation",
    formLabel7: 'Message de description',
    formLabel8: "Enregistrer l'image de fond",
    formLabel9: "Image d'arrière - plan du marché",
    fileFail: 'Veuillez télécharger les images et le format',
    optionsBtn: 'Soumettre',
  },
  'appChangeLoginPass': {
    navTitle: 'Modifier le mot de passe de connexion',
    formLabel1: 'Ancien mot de passe',
    formLabel2: 'Nouveau mot de passe',
    formLabel3: 'Code de vérification',
    formHint1: "Veuillez saisir l'ancien mot de passe",
    formHint2: 'Veuillez saisir un nouveau mot de passe',
    formHint3: 'Veuillez saisir le code de vérification',
    formOption: 'Envoyer',
    sendOption: 'Compte à rebours :',
    optionsBtn: 'Confirmer',
  },
  'appChangePayPass': {
    navTitle: 'Modifier le mot de passe de paiement',
    formLabel1: 'Mot de passe de paiement',
    formLabel2: 'Code de vérification',
    formHint1: 'Veuillez saisir le mot de passe de paiement',
    formHint2: 'Veuillez saisir le code de vérification',
    formOption: 'Envoyer',
    sendOption: 'Compte à rebours :',
    optionsBtn: 'Confirmer',
  },
  "appFundingRecordsIndex": {
    navTitle: "Enregistrement du fonds",
    searchRecordsTitle: "Type de fonds",
    searchDateTitle: "Sélection de l'heure",
    searchFundingLabel1: 'Tous les fonds',
    searchFundingLabel2: 'Type de dépôt',
    searchFundingLabel3: 'Enregistrement de retrait',
    searchOptions1: 'Réinitialiser',
    searchOptions2: 'Confirmer',
    searchDateAffirm: 'confirmer',
    searchDateClose: 'Annuler',
    stareTimePlace: 'Heure de début',
    endTimePlace: 'Heure de fin',
    typeAll: 'Tous',
  },
  "appProjectIndex": {
    navTitle: 'Liste de produits',
    searchPlace: 'Veuillez saisir le nom du produit que vous souhaitez rechercher',
    shelvesLabel: 'étagères supérieures et inférieures',
    brandPlace: 'Veuillez sélectionner une marque',
    classPlace: 'Veuillez sélectionner une catégorie',
    searchOptions1: "Réinitialiser",
    searchOptions2: 'Confirmer',
    shelvesTag1: "Tous",
    shelvesTag2: "sur les étagères",
    shelvesTag3: "hors étagères",
    salesLabel: 'Ventes :',
    dialogAffirm: 'Confirmer',
    dialogClose: 'Annuler',
    listLabel: 'Étagères haut et bas :',
  },
  "appProjectLibraryIndex": {
    navTitle: "Bibliothèque de produits",
    searchPlace: 'Veuillez saisir le nom du produit que vous souhaitez rechercher',
    brandPlace: 'Veuillez sélectionner une marque',
    classPlace: 'Veuillez sélectionner une catégorie',
    searchOptions1: "Réinitialiser",
    searchOptions2: 'Confirmer',
    salesLabel: 'Ventes :',
    dialogAffirm: 'Confirmer',
    dialogClose: 'Annuler',
    productPackageLabel1: 'Pack produit',
    productPackageLabel2: '(ajout rapide de produits)',

    selectAll: "Sélection multiple",
    selectHint: "seuls les produits jusqu'à 50 sont pris en charge",
    submitOptions: "soumettre",
    dialogHint: 'Êtes - vous sûr de mettre ce produit en stock en vrac?',

  },
  'appProductPackageIndex': {
    navTitle: "Pack produit",
    listLabel1: 'étoiles recommandées:',
    listLabel2: 'État de synchronisation:',
    listLabel3: 'nombre de produits:',
    optionsBtn: "synchronisation",
    dialogHint: "confirmer l'utilisation de ce Pack de produits?",
    dialogOptions1: "annuler",
    dialogOptions2: "confirmer",
    messageToast: 'soumission réussie, profil produit synchronisé...',
  },
  'appProductPackageList': {
    navTitle: "détails du pack produit",
    salesLabel: 'vente：',
  },
  "appProductFirstIndex": {
    navTitle: 'Informations sur le produit',
    nextStep: 'Étape suivante',
    label1: 'Nom du produit :',
    label2: 'Marque du produit :',
    label3: 'Catégorie de produit :',
    label4: "Prix minimum d'achat: ",
    label5: 'Prix de vente minimum :',
    label6: 'Prix de vente minimum :',
    label7: 'Image de couverture :',
    label8: 'Photo du produit :',

  },
  'appProductNextStepIndex': {
    navTitle: 'Spécifications du produit',
    previous: 'étape précédente',
    navBtn: 'Soumettre',
    label1: "Prix d'achat :",
    placeHint: 'Veuillez entrer',
    label2: 'Prix :',
    label3: 'Prix du marché :',
    specLabel: 'Attribut de spécification :',
    optionBtn: "Confirmer",
    validateMessage1: "Le prix d'origine ne peut pas être inférieur au prix d'achat",
    validateMessage2: "Le prix d'origine ne peut pas être inférieur au prix de vente actuel",
    validateMessage3: "Le prix de vente ne peut être inférieur au prix d'achat",
    validateMessage4: "Le prix de vente ne peut pas être supérieur au prix d'origine",
    shelvesTitle: "Voulez- vous charger ou décharger les étagères? ",
    shelvesOptions1: 'Non',
    shelvesOptions2: 'Oui',
  },
  "appOrderIndex": {
    tagsLabel1: 'Tous',
    orderTotalNumber: 'Numéro total de commande',
    listLabel1: 'Date de commande :',
    listLabel2: 'Ventes totales :',
    listLabel3: 'Quantité du produit :',
    listLabel4: 'Statut de la commande :',
    listLabel5: 'Statut du paiement :',
    listHint: "Achat",
    searchOptionBtn: 'Rechercher',
    searchPlace: 'Veuillez saisir le contenu de la recherche',
    searchDateStartPlace: 'Heure de début',
    searchDateEndPlace: 'Heure de fin',
    searchDateAffirm: 'confirmer',
    searchDateClose: 'Annuler',
    searchTypePayTitle: 'Statut du paiement',
    payTags1: 'Tous',
    payTags2: 'Paiement en attente',
    payTags3: 'Payé',
    searchTypeOrderTitle: 'Statut de la commande',
    searchOptions1: 'Réinitialiser',
    searchOptions2: 'Confirmer',
    purDialogTitle: 'Acheter tout',
    purDialogLabel1: "Montant de l'achat:",
    purDialogLabel2: 'Montant des ventes :',
    purDialogLabel3: 'Montant du bénéfice :',
    purOptions: "Confirmer",
    purRowTitle: 'Achat',
    purRowLabel1: "Montant de l'achat: ",
    purRowLabel2: 'Montant des ventes :',
    purRowLabel3: 'Montant du bénéfice :',
  },
  "appOrderDetailIndex": {
    navTitle: 'Détails de la commande',
    detailInfoLabel1: 'Numéro de commande :',
    detailInfoLabel2: 'Heure de la commande :',
    detailInfoLabel3: 'Mode de paiement :',
    detailInfoLabel4: 'Statut de la commande :',
    detailInfoLabel5: 'Statut du paiement :',
    productLabel1: 'Informations sur le produit',
    productAttributesLabel: 'Attributs du produit :',
    productAccountLabel1: 'Ventes :',
    productAccountLabel2: "Montant de l'achat :",
    countInfoLabel1: 'Ventes totales :',
    countInfoLabel2: "Montant total de l'achat :",
    countInfoLabel3: 'Bénéfice total :',
    countInfoLabel4: 'Quantité totale :',
    countInfoLabel5: 'Taux de remise :',
  },
  "appOrderDetailLogistics": {
    navTitle: 'Logistique des commandes',
    logisticsLabel: 'Commande',
    listLabel1: "Nom :",
    listLabel2: "Téléphone :",
    listLabel3: "Code postal :",
    listLabel4: "Adresse :",
  },
  "appOrderDetailRecord": {
    navTitle: "Enregistrement de la commande",
    logisticsLabel: 'Commande',
    recordEnumLabel1: 'Le client crée la commande',
    recordEnumLabel2: 'Ordre de paiement client',
    recordEnumLabel3: 'Bon de commande en magasin',
    recordEnumLabel4: 'La commande commence à être expédiée',
    recordEnumLabel5: "L'utilisateur confirme la réception",
    recordEnumLabel6: 'Le remboursement du système arrive',
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: 'Version upgrade, please confirm? ',
    loadingTitle: 'Downloading...',
    systemUpgrade: 'System upgrade',
    upgradeImmediately: 'Confirm',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "Chargement",
    finishedText: "Aucun",
    loadingText: "Chargement",
    nullText: "Aucune donnée pour l'instant",
    loadingFinished: "Chargement terminé",
    listNullText: 'Plus rien',
    pullIngText: 'Déroulez pour actualiser',
    loosingText: 'Relâcher pour actualiser',
    pullingSuccess: 'Chargement déroulant réussi',
    webSocketSuccess: 'Vous avez un nouveau message',
    imLinkSuccess: 'Connexion au service de messagerie instantanée réussie ! ',
    imNonsupport: 'Ce navigateur ne prend pas en charge Websocket',
    imLinkLoading: 'La messagerie instantanée établit un lien, veuillez patienter...',
    imLinkErrorLoading: 'La connexion IM a échoué, veuillez actualiser la page ou contacter le développeur ! ',
    dialogHint: 'avez - vous déjà un compte?',
    dialogClose: "inscription",
    dialogAffirm: "se connecter",
  },
};
export default obj;