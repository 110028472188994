// 日语
let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  "appWebSocketList": {
    navTitle: "メッセージ",
    IMErrorMessage: 'IM 接続に失敗しました。クリックしてもう一度お試しください!',
  },
  "appWebSocketDetails": {
    sendOption: '送信',
    send: '送信',
    fileFail: '写真をアップロードしてフォーマットしてください',
    IMportError: '現在接続されていません。もう一度お試しください! ',
    IMErrorMessage: 'IM接続に失敗しました。もう一度お試しください! ',
    retryBtn: '再試行',
  },
  "appLoginIndex": {
    navTitle: 'ログイン',
    projectName: 'ストアの管理',
    formLabel1: 'メール',
    formLabel2: 'パスワード',
    placeholder: '入力してください',
    hint: "アカウントをお持ちでない場合は,",
    clickRegister: '登録するにはクリックしてください',
    forgetPass: 'パスワードを忘れました',
    optionBtn: 'ログイン',
    validateError: '入力を完了してください! ',
  },
  "appForgetPassIndex": {
    navTitle: 'パスワードを忘れた場合',
    formLabel1: 'ログインメールボックス',
    formLabel2: '確認コード',
    formLabel3: '新しいパスワード',
    formLabel4: 'パスワードの確認',
    formHint1: 'ログインメールアドレスを入力してください',
    formHint2: '新しいパスワードを入力してください',
    formHint3: '確認コードを入力してください',
    formHint4: 'パスワードを2回入力してください',
    formOption: '送信',
    sendOption: 'カウントダウン:',
    optionsBtn: '確認',
  },
  'footer': {
    home: "ストア",
    project: "製品",
    info: "メッセージ",
    order: "注文",
    user: "マイ",
  },
  'setDrawer': {
    label1: '多言語設定',
    label2: 'ストア登録',
    label3: 'ストアログイン',
    label4: 'カスタマーサービス',
    label5: 'Androidアプリケーションのダウンロード',
    label6: 'iOSアプリケーションのダウンロード',
    optionsBtn: 'キャンセル',
  },
  'appHomeIndex': {
    accountLabel1: '売上',
    accountLabel2: 'トラフィック',
    cardLabel1: 'データ概要',
    cardLabel2: 'データレポート',
    cardLabel3: '金額概要',
    ech1Title: '総売上',
    ech2Title: '総注文数',
    ech3Title: '店舗トラフィック',
    echBuoy1Title: '総売上',
    echBuoy2Title: '総注文数',
    echBuoy3Title: '店舗トラフィック',
    dataCardTitle1: '売上',
    dataCardTitle2: 'トラフィック',
    dataCardTitle3: '総注文数',
    dataCardTitle4: '未払い金額合計',
    dataCardDayTags: '今日',
    dataYesterTags: "昨日のdata",
    dataYesterTags1: "未確認注文の数量",
    cardMenusLabel1: '税金',
    cardMenusLabel2: 'ストアドバリュー',
    cardMenusLabel3: '引き出し',
    cardMenusLabel4: '招待の共有',
    cardMenusLabel5: '商品リスト',
    cardMenusLabel6: '注文リスト',
    cardMenusLabel7: 'ビジネスレポート',
    taxPopupTitle: '現在税金を支払う必要があります:',
    popupBtn: '税金を支払いに行きましょう',
    cardStateLabel1: '残高',
    cardStateLabel2: '引き出し',
    cardStateLabel3: '収入',
    cardStateLabel4: 'チーム',
    cardSellHotTitle: '売れ筋商品トップ 10',
    salesLabel: '売上:',
  },
  'appSetLanguage': {
    navTitle: '多言語設定',
    affirm: '確認',
  },
  "appServiceIndex": {
    navTitle: 'カスタマーサービス',
    customerService: '専用カスタマーサービス',
    helloTitle: 'こんにちは',
    welcomeTitle: '私はあなたのものです。以下のチャネルを通じて私に連絡できます',
    iconInformation: '相談用の通信ソフトウェアを選択してください',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
  },
  'appUserIndex': {
    blanceLabel: '残高',
    blanceOption1: '出金',
    blanceOption2: '店舗価値',
    incomeLabel1: '累計収入',
    incomeLabel2: 'チーム収入',
    listLabel1: '店舗情報',
    listLabel2: '出金方法設定',
    listLabel3: '資金記録',
    listLabel4: '事業報告',
    listLabel5: '割引活動',
    listLabel7: 'ローン',
    listLabel8: 'ショッピングモールに行く',
    listLabel9: 'アプリケーションをダウンロード',
    listLabelLast: '終了',
  },
  'downloadAppIndex': {
    navTitle: 'ダウンロード',
    downloadAndroidApp: "Android ダウンロード",
    downloadIosApp: "IOS ダウンロード",
  },
  "appDiscountActivity": {
    navTitle: '割引アクティビティ',
    popupLabel1: '金額',
    popupPlace1: '金額を入力してください',
    popupLabel2: '割引',
    popupPlace2: '割引を入力してください',
    popupOption: '確認',
    listLabel1: '必要な金額:',
    listLabel2: '割引率:',
    listLabel3: '割引時間:',
    dialogTitle: "削除するかどうかを確認してください!",
    dialogAffirm: '確認',
    dialogClose: "キャンセル",
  },
  "appInviteIndex": {
    navTitle: '新しいアクティビティを招待する',
    headerTitle: '友達を招待して報酬を獲得する',
    copyOption: 'copy',
    copySuccess: 'コピー成功',
    inviteTitle: '招待総数',
    inviteLabel1: '招待人数:',
    inviteLabel2: '合計手数料:',
    ruleTitle: '招待ルール',
    ruleHint1: ' 1. 招待者はプロモーションリンクを共有して友達を招待して登録を完了することができ,招待された友達はあなたに関連付けられます;',
    ruleHint2: ' 2. ユーザーを招待して支払いを完了させた後,あなたは彼らの利益の一部を得ることができます;',
    ruleHint3: ' 3. 第3レベルの招待報酬のみがサポートされており,報酬率は<%,>%,@%; です。',

  },
  "appInviteUserRecordIndex": {
    navTitle: 'ユーザー招待レコード',
    recordTitle1: 'ユーザー総数',
    recordTitle2: 'レベル 1',
    recordTitle3: 'レベル 2',
    recordTitle4: 'レベル 3',
    tabLabel1: 'すべて',
    tabLabel2: 'レベル 1',
    tabLabel3: '第 2 レベル',
    tabLabel4: 'レベル 3',
    listLabel1: 'レベル:',
    listLabel2: '利益:',
  },
  "appInviteIncomeRecordIndex": {
    navTitle: '招待収益記録',
    recordTitle1: '総収益',
    recordTitle2: 'レベル 1',
    recordTitle3: 'レベル 2',
    recordTitle4: 'レベル 3',
    tabLabel1: 'すべて',
    tabLabel2: 'レベル 1',
    tabLabel3: '第 2 レベル',
    tabLabel4: 'レベル 3',
    listLabel1: '利益:',
    listLabel2: '割合:',
  },
  "appWithdrawIndex": {
    navTitle: '出金',
    currencyType1: '法定通貨',
    currencyType2: '仮想通貨',
    formLabel1: '通貨の種類:',
    formPlace1: '通貨の種類を選択してください',
    formLabel2: '通貨を選択してください:',
    formPlace2: '通貨の種類を選択してください',
    formLabel3: '出金金額:',
    formPlace3: '出金金額を入力してください',
    formLabel4: '出金パスワード:',
    formPlace4: '出金パスワードを入力してください:',
    balanceLabel: '現在の残高:',
    amountLabel: '実際の到着:',
    optionsBtn: '送信',
    dialogClose: 'キャンセル',
    dialogAffirm: '確認',
    currencySelectError: '通貨の種類を選択してください',
    currencyError: '通貨を選択してください',
    affirmDialogDialogTitle1: '支払いパスワードが設定されていません。設定しますか?',
    affirmDialogDialogTitle2: '出金チャネルが設定されていません。設定しますか?',
    affirmDialogBtn2: 'キャンセル',
    affirmDialogBtn1: '確認',
  },
  'appSetWithdrawalIndex': {
    navTitle: '出金方法の設定',
    currencyType1: '法定通貨',
    currencyType2: '仮想通貨',
    formLabel1: '通貨の種類:',
    formPlace1: '通貨の種類を選択してください',
    formLabel2: '通貨を選択してください:',
    formPlace2: '通貨の種類を選択してください',
    addressLabel: '住所:',
    addressPlace: '仮想通貨のアドレスを入力してください',
    hintLabel: '重要なヒント',
    hintValue: '¥ 資産のみを転送してください',
    optionsBtn: '送信',
    dialogClose: 'キャンセル',
    dialogAffirm: '確認',
    currencySelectError: '通貨の種類を選択してください',
    currencyError: '通貨を選択してください',
    currencyFail: 'まだ開いていません',
  },
  //  提现设置-法定货币-BRL
  'brlLanguage': {
    formLabel1: '本名',
    formPlace1: '本名を入力してください',
    formLabel2: 'メールアドレス',
    formPlace2: 'メールアドレスを入力してください',
    formLabel3: '携帯電話番号',
    formPlace3: '携帯電話番号を入力してください',
    formLabel4: 'PIXタイプ',
    formPlace4: 'PIXタイプを選択してください',
    formLabel5: 'PIXアカウント',
    formPlace5: 'PIXアカウント番号を入力してください',
  },
  //  提现设置-法定货币-EUR
  'eurLanguage': {
    formLabel1: '国/地域',
    formPlace1: '国/地域を入力してください',
    formLabel2: '名前',
    formPlace2: '名前を入力してください',
    formLabel3: '姓',
    formPlace3: '姓を入力してください',
    formLabel4: 'swift',
    formPlace4: 'swiftを入力してください',
    formLabel5: '銀行名',
    formPlace5: '銀行名を入力してください',
    formLabel6: 'iban',
    formPlace6: 'ibanを入力してください',
    formLabel7: '携帯電話番号',
    formPlace7: '携帯電話番号を入力してください',
  },
  //  提现设置-法定货币-USD
  'usdLanguage': {
    formLabel1: '国/地域',
    formPlace1: '国/地域を入力してください',
    formLabel2: '名前',
    formPlace2: '名前を入力してください',
    formLabel3: '姓',
    formPlace3: '姓を入力してください',
    formLabel4: 'swift',
    formPlace4: 'swiftを入力してください',
    formLabel5: 'ach',
    formPlace5: 'achを入力してください',
    formLabel6: '銀行名',
    formPlace6: '銀行名を入力してください',
    formLabel7: '銀行口座',
    formPlace7: '銀行口座を入力してください',
    formLabel8: '都道府県',
    formPlace8: '都道府県を入力してください',
    formLabel9: '携帯電話番号',
    formPlace9: '携帯電話番号を入力してください',
  },
  //  提现设置-法定货币-GBP
  'gbpLanguage': {
    formLabel1: '国/地域',
    formPlace1: '国/地域を入力してください',
    formLabel2: '名前',
    formPlace2: '名前を入力してください',
    formLabel3: '姓',
    formPlace3: '姓を入力してください',
    formLabel4: '銀行コード',
    formPlace4: '銀行コードを入力してください',
    formLabel5: '銀行名',
    formPlace5: '銀行名を入力してください',
    formLabel6: '銀行口座',
    formPlace6: '銀行口座を入力してください',
    formLabel7: '携帯電話番号',
    formPlace7: '携帯電話番号を入力してください',
  },
  //  提现设置-法定货币-JPY
  'jpyLanguage': {
    formLabel1: '国/地域',
    formPlace1: '国/地域を入力してください',
    formLabel2: '名前',
    formPlace2: '名前を入力してください',
    formLabel3: '姓',
    formPlace3: '姓を入力してください',
    formLabel4: '全銀',
    formPlace4: '全銀を入力してください',
    formLabel5: '銀行名',
    formPlace5: '銀行名を入力してください',
    formLabel6: '銀行口座',
    formPlace6: '銀行口座を入力してください',
    formLabel7: '都道府県',
    formPlace7: '都道府県を入力してください',
    formLabel8: '携帯電話番号',
    formPlace8: '携帯電話番号を入力してください',
  },
  //  提现设置-法定货币-AUD
  'audLanguage': {
    formLabel1: '国/地域',
    formPlace1: '国/地域を入力してください',
    formLabel2: '名前',
    formPlace2: '名前を入力してください',
    formLabel3: '姓',
    formPlace3: '姓を入力してください',
    formLabel4: 'BSB',
    formPlace4: 'BSBを入力してください',
    formLabel5: '銀行名',
    formPlace5: '銀行名を入力してください',
    formLabel6: '銀行口座',
    formPlace6: '銀行口座を入力してください',
    formLabel7: '都道府県',
    formPlace7: '都道府県を入力してください',
    formLabel8: '携帯電話番号',
    formPlace8: '携帯電話番号を入力してください',
  },
  //  提现设置-法定货币-krw
  'krwLanguage': {
    formLabel1: '名前',
    formPlace1: '名前を入力してください',
    formLabel2: '姓',
    formPlace2: '姓を入力してください',
    formLabel3: '銀行名',
    formPlace3: '銀行名を入力してください',
    formLabel4: '銀行口座',
    formPlace4: '銀行口座を入力してください',
    formLabel5: '携帯電話番号',
    formPlace5: '携帯電話番号を入力してください',
  }
  ,
  //  提现设置-法定货币-mxn
  'mxnLanguage': {
    formLabel1: '銀行名',
    formPlace1: '銀行名を入力してください',
    formLabel2: '口座',
    formPlace2: '口座を入力してください',
    formLabel3: 'clabe口座',
    formPlace3: 'clabe口座を入力してください',
    formLabel4: '携帯電話番号',
    formPlace4: '携帯電話番号を入力してください',
  },
  'appStatisticsReportIndex': {
    navTitle: '営業報告書',
    cardLabel1: '総売上',
    cardLabel2: '総購入金額',
    cardLabel3: '総利益',
    cardLabel4: '総注文数',
    listLabel1: '時間:',
    listLabel2: '注文:',
    listLabel3: '売上:',
    listLabel4: '購入金額:',
    profitLabel: '利益',
    searchDateTitle: 'ドキュメント時間',
    searchIntervalTitle: '間隔時間',
    searchStartTimePlace: '開始時間',
    searchEndTimePlace: '終了時間',
    searchOptions1: 'リセット',
    searchOptions2: '確認',
    searchDateAffirm: '確認',
    searchDateClose: 'キャンセル',
    searchIntervalLabel1: "すべて",
    searchIntervalLabel2: "今日",
    searchIntervalLabel3: "昨日",
    searchIntervalLabel4: "今週",
    searchIntervalLabel5: "今月",
    searchIntervalLabel6: "今年",
    searchIntervalOptions: "キャンセル",
  },
  "appAmountRechargeIndex": {
    navTitle: 'ストアドバリュー',
    currencyType1: '法定通貨',
    currencyType2: '仮想通貨',
    formLabel1: '通貨タイプ:',
    formPlace1: '通貨タイプを選択してください',
    formLabel2: '通貨を選択してください:',
    formPlace2: '通貨タイプを選択してください',
    formLabel3: 'ストアドバリュー金額:',
    formPlace3: 'ストアドバリュー金額を入力してください',
    formLabel4: 'チャージ方法',
    formPlace4: '保管方法を選択してください:',
    balanceLabel: '現在の残高:',
    amountLabel: '実際の到着:',
    optionsBtn: '送信',
    dialogClose: 'キャンセル',
    dialogAffirm: '確認',
    currencySelectError: '通貨タイプを選択してください',
    currencyError: '通貨を選択してください',
    successCopy: "コピーに成功しました",
    hintLabel: "重要なリマインダー:",
    hintValue: "¥ 資産の転送のみが許可されています",
    legalHint: 'カスタマーサービスに連絡してください！'
  },
  'appShopInfoIndex': {
    navTitle: 'ストア情報',
    navEditText: '編集',
    formLabel1: 'ストア画像',
    formLabel2: 'ストア名',
    formLabel3: 'ログインメール',
    formLabel4: '招待コード',
    formLabel5: 'ログインパスワード',
    formLabel6: '支払いパスワード',
    formLabel7: '説明メッセージ',
    formLabel8: '背景画像を保存',
    formLabel9: 'マーケット背景画像',
    formLabel10: '証明書情報',
    formHint1: '書類の表面',
    formHint2: '証明書の裏面',
    fileFail: '写真をアップロードしてフォーマットしてください',
    optionsBtn: '送信',
  },
  'appShopInfoEdit': {
    navTitle: '店舗情報編集者',
    formLabel1: 'ストア画像',
    formLabel2: 'ストア名',
    formLabel3: 'ログインメール',
    formLabel4: '招待コード',
    formLabel7: '説明メッセージ',
    formLabel8: '背景画像を保存',
    formLabel9: 'マーケット背景画像',
    fileFail: '写真をアップロードしてフォーマットしてください',
    optionsBtn: '送信',
  },
  'appChangeLoginPass': {
    navTitle: 'ログインパスワードの変更',
    formLabel1: '古いパスワード',
    formLabel2: '新しいパスワード',
    formLabel3: '確認コード',
    formHint1: '古いパスワードを入力してください',
    formHint2: '新しいパスワードを入力してください',
    formHint3: '確認コードを入力してください',
    formOption: '送信',
    sendOption: 'カウントダウン:',
    optionsBtn: '確認',
  },
  'appChangePayPass': {
    navTitle: '支払いパスワードの変更',
    formLabel1: '支払いパスワード',
    formLabel2: '確認コード',
    formHint1: '支払いパスワードを入力してください',
    formHint2: '確認コードを入力してください',
    formOption: '送信',
    sendOption: 'カウントダウン:',
    optionsBtn: '確認',
  },
  "appFundingRecordsIndex": {
    navTitle: 'ファンド記録',
    searchRecordsTitle: 'ファンドタイプ',
    searchDateTitle: '時間選択',
    searchFundingLabel1: 'すべてのファンド',
    searchFundingLabel2: '入金タイプ',
    searchFundingLabel3: '出金記録',
    searchOptions1: 'リセット',
    searchOptions2: '確認',
    searchDateAffirm: '確認',
    searchDateClose: 'キャンセル',
    stareTimePlace: '開始時間',
    endTimePlace: '終了時間',
    typeAll: 'すべて',

  },
  "appProjectIndex": {
    navTitle: '商品リスト',
    searchPlace: '検索したい商品名を入力してください',
    shelvesLabel: '上段と下段の棚',
    brandPlace: 'ブランドを選択してください',
    classPlace: 'カテゴリを選択してください',
    searchOptions1: "リセット",
    searchOptions2: "確認",
    shelvesTag1: "すべて",
    shelvesTag2: "棚上",
    shelvesTag3: "棚外",
    salesLabel: '販売:',
    dialogAffirm: '確認',
    dialogClose: 'キャンセル',
    listLabel: '上段と下段の棚:',
  },
  "appProjectLibraryIndex": {
    navTitle: "製品ライブラリ",
    searchPlace: "検索したい製品名を入力してください",
    brandPlace: "ブランドを選択してください",
    classPlace: "カテゴリを選択してください",
    searchOptions1: "リセット",
    searchOptions2: "確認",
    salesLabel: "販売:",
    dialogAffirm: "確認",
    dialogClose: "キャンセル",
    productPackageLabel1: '製品パッケージ',
    productPackageLabel2: '（クイック追加製品）',

    selectAll: '複数選択',
    selectHint: '最大50製品のみサポート',
    submitOptions: '送信',
    dialogHint: 'この製品をロットアップしてよろしいですか。',

  },
  'appProductPackageIndex': {
    navTitle: "製品パッケージ",
    listLabel1: '推薦星:',
    listLabel2: '同期状態:',
    listLabel3: '製品数:',
    optionsBtn: "同期",
    dialogHint: "このパッケージの使用を確認しますか？",
    dialogOptions1: "キャンセル",
    dialogOptions2: "確認",
    messageToast: 'コミット成功、製品資料同期中...'
  },
  'appProductPackageList': {
    navTitle: 'パッケージ詳細',
    salesLabel: '販売:',
  },
  "appProductFirstIndex": {
    navTitle: '製品情報',
    nextStep: '次のステップ',
    label1: '製品名:',
    label2: '製品ブランド:',
    label3: '製品カテゴリ:',
    label4: '最低購入価格:',
    label5: '最低販売価格:',
    label6: '最低販売価格:',
    label7: 'カバー画像:',
    label8: '製品画像:',

  },
  'appProductNextStepIndex': {
    navTitle: 'Product Specifications',
    previous: 'previous step',
    navBtn: 'Submit',
    label1: 'Purchase price:',
    placeHint: 'Please enter',
    label2: 'Price:',
    label3: 'Market price:',
    specLabel: 'Specification attribute:',
    optionBtn: "Confirm",
    validateMessage1: 'The original price cannot be lower than the purchase price',
    validateMessage2: 'The original price cannot be lower than the current sales price',
    validateMessage3: 'The sales price cannot be lower than the purchase price',
    validateMessage4: 'The sales price cannot be greater than the original price',
    shelvesTitle: 'Do you want to load or unload the shelves? ',
    shelvesOptions1: 'No',
    shelvesOptions2: 'Yes',
  },
  "appOrderIndex": {
    tagsLabel1: 'すべて',
    orderTotalNumber: '合計注文数',
    listLabel1: '注文日:',
    listLabel2: '合計売上:',
    listLabel3: '商品数量:',
    listLabel4: '注文状況:',
    listLabel5: '支払い状況:',
    listHint: '購入',
    searchOptionBtn: '検索',
    searchPlace: '検索内容を入力してください',
    searchDateStartPlace: '開始時間',
    searchDateEndPlace: '終了時間',
    searchDateAffirm: '確認',
    searchDateClose: 'キャンセル',
    searchTypePayTitle: '支払い状況',
    payTags1: 'すべて',
    payTags2: '支払い保留中',
    payTags3: '支払い済み',
    searchTypeOrderTitle: '注文ステータス',
    searchOptions1: 'リセット',
    searchOptions2: '確認',
    purDialogTitle: 'すべて購入',
    purDialogLabel1: '購入金額:',
    purDialogLabel2: '売上金額:',
    purDialogLabel3: '利益金額:',
    purOptions: "確認",
    purRowTitle: '購入',
    purRowLabel1: '購入金額:',
    purRowLabel2: '売上金額:',
    purRowLabel3: '利益金額:',
  },
  "appOrderDetailIndex": {
    navTitle: '注文の詳細',
    detailInfoLabel1: '注文番号:',
    detailInfoLabel2: '注文時間:',
    detailInfoLabel3: '支払い方法:',
    detailInfoLabel4: '注文状況:',
    detailInfoLabel5: '支払い状況:',
    productLabel1: '製品情報',
    productAttributesLabel: '製品属性:',
    productAccountLabel1: '売上:',
    productAccountLabel2: '購入金額:',
    countInfoLabel1: '総売上:',
    countInfoLabel2: '総購入金額:',
    countInfoLabel3: '総利益:',
    countInfoLabel4: '総数量:',
    countInfoLabel5: '割引率:',
  },
  "appOrderDetailLogistics": {
    navTitle: '注文物流',
    logisticsLabel: '注文',
    listLabel1: "名前:",
    listLabel2: "電話番号:",
    listLabel3: "郵便番号:",
    listLabel4: "住所:",
  },
  "appOrderDetailRecord": {
    navTitle: '注文記録',
    logisticsLabel: '注文',
    recordEnumLabel1: '顧客が注文を作成',
    recordEnumLabel2: '顧客の支払い注文',
    recordEnumLabel3: 'ストア購入注文',
    recordEnumLabel4: '注文の発送開始',
    recordEnumLabel5: 'ユーザーが受領を確認',
    recordEnumLabel6: 'システム返済が到着',

  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: 'バージョンアップグレード,確認してください?',
    loadingTitle: 'ダウンロード中...',
    systemUpgrade: 'システムアップグレード',
    upgradeImmediately: '確認',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "読み込み中",
    finishedText: "なし",
    loadingText: "読み込み中",
    nullText: 'まだデータがありません',
    loadingFinished: "読み込みが完了しました",
    listNullText: 'これ以上はありません',
    pullIngText: 'プルダウンして更新してください',
    loosingText: 'リリースして更新してください',
    pullingSuccess: 'プルダウンの読み込みに成功しまし,た',
    webSocketSuccess: '新しいメッセージがあります',
    imLinkSuccess: 'IM サービス接続に成功しました! ',
    imNonsupport: 'このブラウザは Websocket をサポートしていません',
    imLinkLoading: 'IM がリンク中です。お待​​ちください...',
    imLinkErrorLoading: 'IM 接続に失敗しました。ページを更新するか、開発者に連絡してください! ',
    dialogHint: "アカウントはお持ちですか？",
    dialogClose: '登録',
    dialogAffirm: 'ログイン',
  },
};
export default obj;
