<template>
  <div class="order-page-warper" id="orderPageID">
    <div class="order-header" v-if="orderTags.length">
      <van-tabs
        v-model="tagsActiveIndex"
        sticky
        :swipe-threshold="3"
        @change="onChangeTags"
      >
        <van-tab
          v-for="(item, index) in orderTags"
          :key="index"
          :title="item.label"
        ></van-tab>
      </van-tabs>
    </div>
    <div class="order-total-number flexbox" v-if="orderTags.length">
      <p class="left">
        <span class="label">{{ language.orderTotalNumber }}</span>
        <template v-if="formObj.total">
          (
          <span class="value">{{ formObj.total }}</span>
          )
        </template>
      </p>
      <svg-icon icon-class="filter-svg" @click="onSearchPopup"></svg-icon>
    </div>
    <div class="order-page-body">
      <div class="order-list-boxers" v-if="orderTags.length">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishedText"
          :loading-text="loadingText"
          @load="onLoad"
        >
          <template v-if="orderListData.length">
            <div
              class="list-item"
              v-for="(item, index) in orderListData"
              :key="index"
              @click="onChangeView(item)"
            >
              <div class="list-boxers">
                <div class="list-title flexbox">
                  <p class="list-left orderNo">
                    <span>{{ item.no }}</span>
                  </p>
                </div>
                <div class="list-title flexbox">
                  <p class="list-left flexbox">
                    <svg-icon icon-class="order-date"></svg-icon>
                    <span class="label">{{ language.listLabel1 }}</span>
                  </p>
                  <p class="list-right">
                    <span>{{ item.createAt }}</span>
                  </p>
                </div>
                <div class="list-title flexbox">
                  <p class="list-left flexbox">
                    <svg-icon icon-class="order-sales"></svg-icon>
                    <span class="label">{{ language.listLabel2 }}</span>
                  </p>
                  <p class="list-right">
                    <countTo
                      :startVal="0"
                      :endVal="item.totalUnitAmount"
                      :duration="1200"
                      :decimals="2"
                      :separator="','"
                      :prefix="moneyIcon"
                    ></countTo>
                  </p>
                </div>
                <div class="list-title flexbox">
                  <p class="list-left flexbox">
                    <svg-icon icon-class="order-number"></svg-icon>
                    <span class="label">{{ language.listLabel3 }}</span>
                  </p>
                  <p class="list-right">
                    <span>{{ item.number }}</span>
                  </p>
                </div>
                <div class="list-title flexbox">
                  <p class="list-left flexbox">
                    <svg-icon icon-class="order-state"></svg-icon>
                    <span class="label">{{ language.listLabel4 }}</span>
                  </p>
                  <p class="list-right">
                    <span>{{ item.stateName }}</span>
                  </p>
                </div>
                <div class="list-title flexbox">
                  <p class="list-left flexbox">
                    <svg-icon icon-class="order-back"></svg-icon>
                    <span class="label">{{ language.listLabel5 }}</span>
                  </p>
                  <p class="list-right">
                    <span>{{ item.backAmountName }}</span>
                  </p>
                </div>
              </div>
              <div class="list-account flexbox">
                <div class="account-left">
                  <p class="account-title">
                    <countTo
                      :startVal="0"
                      :endVal="item.totalProfitAmount"
                      :duration="1200"
                      :decimals="2"
                      :separator="','"
                      :prefix="moneyIcon"
                    ></countTo>
                  </p>
                  <p class="profit-title">
                    ({{ language.listHint }}
                    <countTo
                      :startVal="0"
                      :endVal="item.totalPurchaseAmount"
                      :duration="1200"
                      :decimals="2"
                      :separator="','"
                      :prefix="moneyIcon"
                    ></countTo>
                    )
                  </p>
                </div>
                <div
                  class="account-right"
                  @click.stop="onChangePur('row', item)"
                  v-if="item.state === 2"
                >
                  <svg-icon icon-class="order-shop2"></svg-icon>
                </div>
              </div>
            </div>
          </template>
          <default-page v-else></default-page>
        </van-list>
      </div>
    </div>
    <div class="footer-page">
      <footerCard :key="count"></footerCard>
    </div>
    <div class="pur-svg-boxers">
      <svg-icon icon-class="order-shop" @click="onChangePur('all')"></svg-icon>
      <!-- <span>{{ language.purAllTitle }}</span> -->
    </div>
    <van-popup
      v-if="searchIsShow"
      v-model="searchIsShow"
      position="top"
      @close="handlerClose"
      round
      class="popup-search"
    >
      <div class="order-search-popup">
        <div class="search-field">
          <van-field
            v-model="formObj.searchValue"
            :placeholder="language.searchPlace"
          >
            <!-- <template #button>
              <van-button size="small" type="primary">
                {{ language.searchOptionBtn }}
              </van-button>
            </template> -->
          </van-field>
        </div>
        <div class="search-date-field flexbox">
          <van-field
            v-model="formObj.startTime"
            readonly
            :placeholder="language.searchDateStartPlace"
            @click="onClickTime('start')"
          ></van-field>
          <span>——</span>
          <van-field
            readonly
            v-model="formObj.endTime"
            :placeholder="language.searchDateEndPlace"
            @click="onClickTime('end')"
          ></van-field>
        </div>
        <div class="search-type-boxers">
          <div class="type-list" v-if="payTypeList.length">
            <div class="list-title">
              <span>{{ language.searchTypePayTitle }}</span>
            </div>
            <div class="list-item">
              <span
                :class="['item-tag', payActiveIndex == index && 'active-tag']"
                v-for="(item, index) in payTypeList"
                :key="index"
                @click="onChangePay(item, index)"
              >
                {{ item.label }}
              </span>
            </div>
          </div>
        </div>
        <div class="search-options">
          <van-button @click="onChangeResult">
            {{ language.searchOptions1 }}
          </van-button>
          <van-button @click="handlerSubmitSearch">{{
            language.searchOptions2
          }}</van-button>
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="dateIsShow"
      position="bottom"
      round
      :style="{ height: '38%' }"
    >
      <van-datetime-picker
        class="date-picker"
        :show-toolbar="true"
        :confirm-button-text="language.searchDateAffirm"
        :cancel-button-text="language.searchDateClose"
        v-model="formObj.currDate"
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="affirmTime"
        @cancel="onChangeTimeCancel"
      />
    </van-popup>
    <van-popup
      v-if="purPopupVisible"
      v-model="purPopupVisible"
      class="pur-dialog"
    >
      <div class="dialog-body">
        <div class="dialog-title">{{ purDialogTitle }}</div>
        <div class="body-list">
          <div class="list-row">
            <span class="label">{{ purDialogLabel2 }}</span>
            <countTo
              :startVal="0"
              :endVal="pupDataObj.totalUnitAmount"
              :duration="1200"
              :decimals="2"
              :separator="','"
              :prefix="moneyIcon"
            ></countTo>
          </div>
          <div class="list-row">
            <span class="label">{{ purDialogLabel1 }}</span>
            <countTo
              :startVal="0"
              :endVal="pupDataObj.totalPurchaseAmount"
              :duration="1200"
              :decimals="2"
              :separator="','"
              :prefix="moneyIcon"
            ></countTo>
          </div>

          <div class="list-row">
            <span class="label">{{ purDialogLabel3 }}</span>
            <countTo
              :startVal="0"
              :endVal="pupDataObj.totalProfitAmount"
              :duration="1200"
              :decimals="2"
              :separator="','"
              :prefix="moneyIcon"
            ></countTo>
          </div>
        </div>
        <div class="footer-options">
          <span @click="onChangePurAffirm">
            {{ language.purOptions }}
          </span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import footerCard from "@/layout/footerCard.vue";
import orderApi from "@/api/orderApi.js";
import moment from "moment-timezone";
import { timeZone } from "@/utils/timeZone";
import { formatDate } from "@/utils/index";
export default {
  name: "appOrderIndex",
  data() {
    return {
      language: this.$language(),
      moneyIcon: localStorage.getItem("moneyIcon"),
      finishedText: this.$language("messageLanguage").finishedText,
      loadingText: this.$language("messageLanguage").loadingText,
      loading: false,
      finished: false,

      count: 0,
      orderTags: [],
      tagsActiveIndex: 0,
      tagsActiveNumber: 0,
      orderListData: [],
      searchIsShow: false,

      dateIsShow: false,
      formObj: {
        page: 1,
        total: 0,
        size: 10,
        searchValue: "",
        currDate: [],
        startTime: "",
        endTime: "",
        payState: 0,
      },
      minDate: new Date(1990, 0, 1),
      maxDate: new Date(2100, 12, 31),
      timeZone: timeZone,
      timeTypeClick: "",
      payTypeList: [],
      payActiveIndex: 0,
      purPopupVisible: false,
      pupDataObj: {
        totalProfitAmount: 0.0,
        totalPurchaseAmount: 0.0,
        totalUnitAmount: 0.0,
      },
      purDialogTitle: "",
      purDialogLabel2: "",
      purDialogLabel1: "",
      purDialogLabel3: "",
      purKey: "",
    };
  },
  components: { footerCard },
  activated() {
    if (this.$route.path === "/appOrderIndex" && !this.count) {
      this.init();
    } else {
      this.count += 1;
    }
  },
  created() {
    console.log(this.count, "created");
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("orderPageID").style["height"] =
        seeHeight + "px";
    });
    this.count += 1;
    this.dateInit();
    this.init();
  },
  mounted() {},
  methods: {
    moment,
    init() {
      this.payTypeList = [
        { value: 0, label: this.language.payTags1 },
        { value: 1, label: this.language.payTags2 },
        { value: 2, label: this.language.payTags3 },
      ];
      this.getTagsList();
    },
    async onChangePurAffirm() {
      let port;
      if (this.purKey === "row") {
        port = orderApi.getOrderPur_interface({ id: this.pupDataObj.id });
      } else {
        port = orderApi.getOrderPurAll_interface();
      }
      const { code, message } = await port;
      if (code == 200) {
        this.$toast({
          message: message,
          duration: 1000,
          type: "success",
        });
        this.purPopupVisible = false;
        this.handlerSubmitSearch();
      }
    },
    onChangePur(key, record) {
      this.purKey = key;
      if (key === "all") {
        this.purDialogTitle = this.language.purDialogTitle;
        this.purDialogLabel2 = this.language.purDialogLabel2;
        this.purDialogLabel1 = this.language.purDialogLabel1;
        this.purDialogLabel3 = this.language.purDialogLabel3;
        this.onChangePurAll();
      }
      if (key === "row") {
        this.purDialogTitle = this.language.purRowTitle;
        this.purDialogLabel2 = this.language.purRowLabel2;
        this.purDialogLabel1 = this.language.purRowLabel1;
        this.purDialogLabel3 = this.language.purRowLabel3;
        this.pupDataObj.totalUnitAmount = record.totalUnitAmount;
        this.pupDataObj.totalPurchaseAmount = record.totalPurchaseAmount;
        this.pupDataObj.totalProfitAmount = record.totalProfitAmount;
        this.pupDataObj.id = record.id;
        this.purPopupVisible = true;
      }
    },
    async onChangePurAll() {
      const { code, data, message } =
        await orderApi.getOrderPurAllView_interface();
      if (code == 200) {
        this.$toast({
          message: message,
          duration: 1000,
          type: "success",
        });

        data.totalProfitAmount = Number(
          (data.totalProfitAmount / 100).toFixed(2)
        );
        data.totalPurchaseAmount = Number(
          (data.totalPurchaseAmount / 100).toFixed(2)
        );
        data.totalUnitAmount = Number((data.totalUnitAmount / 100).toFixed(2));
        this.pupDataObj = data;
        this.purPopupVisible = true;
      }
    },
    onChangeView(record) {
      this.$router.push({
        path: "/appOrderDetailIndex",
        query: { id: record.id },
      });
    },
    handlerSubmitSearch() {
      this.formObj.total = 0;
      this.searchIsShow = false;
      this.finished = false;
      this.loading = false;
      this.formObj.page = 1;
      this.orderListData = [];
      this.onLoad();
    },
    onChangeTags() {
      this.formObj.total = 0;
      this.finished = false;
      this.loading = false;
      this.formObj.page = 1;
      this.orderListData = [];
      this.onLoad();
    },
    onLoad() {
      this.getOrderList();
    },
    async getOrderList() {
      this.loading = true;
      let state = this.tagsActiveIndex;
      if (state == 0) {
        state = "";
      }
      const params = {
        no: this.formObj.searchValue,
        state: state,
        startTime: this.formObj.startTime,
        endTime: this.formObj.endTime,
        page: this.formObj.page,
        size: this.formObj.size,
        payState: this.payTypeList[this.payActiveIndex].value,
      };
      console.log(this.formObj);
      const { code, data } = await orderApi.getOrderList_interface(params);
      if (code == 200) {
        this.loading = false;
        data.list.map((item) => {
          item.totalUnitAmount = Number(
            (item.totalUnitAmount / 100).toFixed(2)
          );
          item.totalPurchaseAmount = Number(
            (item.totalPurchaseAmount / 100).toFixed(2)
          );
          item.totalProfitAmount = Number(
            (item.totalProfitAmount / 100).toFixed(2)
          );
        });
        this.orderListData = this.orderListData.concat(data.list);
        this.formObj.total = data.total;
        if (this.orderListData.length >= this.formObj.total) {
          this.finished = true;
        } else {
          this.finished = false;
          this.formObj.page++;
        }
        console.log(this.formObj);
      }
    },
    async getTagsList() {
      const { code, data } = await orderApi.getOrderTags_interface();
      if (code == 200) {
        let dataList = [];
        this.tagsList = [];
        for (const key in data.stateName) {
          dataList.push({ value: key, name: data.stateName[key] });
        }
        dataList.map((item) => {
          data.overView.map((record) => {
            if (item.value == record.state.toString()) {
              item.label = `${item.name}`;
            }
          });
        });
        this.orderTags = dataList;

        this.orderTags.unshift({
          value: "all",
          label: this.language.tagsLabel1,
        });
      }
    },
    handlerClose() {
      this.searchIsShow = false;
    },
    onSearchPopup() {
      this.searchIsShow = true;
    },
    onChangeResult() {
      this.dateInit();
    },
    dateInit() {
      this.payActiveIndex = 0;
      this.formObj.searchValue = "";
      this.formObj.currDate = [];
      this.formObj.startTime = "";
      this.formObj.endTime = "";
      this.formObj.payState = 0;
      this.formObj.startTime = "";
      this.formObj.endTime = "";
    },
    onClickTime(key) {
      this.timeTypeClick = key;
      let currDate = moment(new Date()).tz(this.timeZone).format("YYYY-MM-DD");
      this.formObj.currDate = new Date(currDate);
      if (key == "start") {
        if (this.formObj.startTime) {
          this.formObj.currDate = new Date(this.formObj.startTime);
        }
      }
      if (key == "end") {
        if (this.formObj.endTime) {
          this.formObj.currDate = new Date(this.formObj.endTime);
        }
      }
      this.dateIsShow = true;
    },
    onChangePay(item, index) {
      this.payActiveIndex = index;
    },
    affirmTime(date) {
      const time = formatDate(date, "yyyy-MM-dd");
      if (this.timeTypeClick == "start") {
        this.formObj.startTime = moment(time + " " + "23:59:59")
          .tz(this.timeZone)
          .format("YYYY-MM-DD");
      }
      if (this.timeTypeClick == "end") {
        this.formObj.endTime = moment(time + " " + "23:59:59")
          .tz(this.timeZone)
          .format("YYYY-MM-DD");
      }
      this.dateIsShow = false;
    },
    onChangeTimeCancel() {
      this.timeTypeClick = "";
      this.dateIsShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.order-page-warper {
  width: 100%;
  height: 100vh;

  .order-header {
    ::v-deep .van-tabs {
      .van-tabs__wrap {
        height: 92px;
      }

      .van-tabs__nav {
        padding: 0px 30px 0px 30px;
      }

      .van-tab {
        padding: 0 12px;
      }

      .van-tab--active {
        color: $blur;
      }

      .van-tabs__line {
        bottom: 0;
        background-color: $blur;
      }
    }
  }

  .order-total-number {
    padding: 30px;
    justify-content: space-between;

    .left {
      line-height: 1.5;

      .label {
        font-size: 32px;
        font-weight: 700;
        color: $color1;
        margin-right: 12px;
      }

      .value {
        font-size: 28px;
        color: $blur;
      }
    }

    svg {
      cursor: pointer;
      width: 32px !important;
      height: 32px !important;
    }
  }

  .order-page-body {
    width: 100%;
    height: calc(100vh - 100px - 92px);
    padding: 0 30px 30px 30px;
    overflow-y: scroll;

    .order-list-boxers {
      .van-list {
        .list-item {
          padding: 24px 30px;
          background: #fff;
          border-radius: 8px;
          margin-bottom: 30px;

          .list-boxers {
            border-bottom: 1px solid $colore;

            .list-title {
              margin-bottom: 16px;
              height: 60px;
              line-height: 2;

              .list-left {
                color: #666;

                i {
                  font-size: 40px;
                  padding-right: 20px;
                  color: #28292b;
                }

                svg {
                  width: 60px !important;
                  height: 60px !important;
                  padding-right: 20px;
                }

                .label {
                  font-size: 26px;
                }
              }

              .orderNo {
                font-size: 28px;
                color: $color1;
                font-weight: 700;
              }

              .list-right {
                span {
                  font-size: 26px;
                  color: #666;
                }
              }
            }
          }

          .list-account {
            line-height: 2;
            padding-top: 20px;
            justify-content: space-between;

            .account-left {
              .account-title {
                font-size: 36px;
                padding-left: 10px;
                color: $blur;
                height: 60px;
                font-weight: 700;
              }

              .profit-title {
                font-size: 26px;
                color: #666;
              }
            }

            .account-right {
              width: 30%;
              display: flex;
              align-items: center;
              justify-content: flex-end;

              svg {
                width: 48px !important;
                height: 48px !important;
                color: $blur;
              }
            }
          }
        }
      }
    }
  }

  .popup-search {
    max-height: 80%;
    min-height: 35%;
    padding: 40px 30px;
    overflow-y: scroll;
    border-radius: 0 0 56px 56px;

    .order-search-popup {
      .search-field {
        margin-bottom: 40px;
      }

      ::v-deep .van-cell {
        border: 1px solid $blur;
        border-radius: 42px;
        padding: 0 12px 0 20px;

        .van-field__control {
          padding: 15px 0;
          font-size: 24px;
          color: $color1 !important;
        }

        .van-button {
          padding: 10px 30px;
          background-color: $blur;
          color: #fff;
          font-size: 24px;
          border-radius: 42px;
          border: 1px solid $blur;
        }
      }

      .search-date-field {
        margin-bottom: 4ex;
      }

      .search-type-boxers {
        margin-bottom: 60px;

        .type-list {
          margin-bottom: 40px;

          .list-title {
            font-size: 32px;
            font-weight: 600;
            line-height: 2;
            height: 60px;
            color: $color1;
          }

          .list-item {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;

            .item-tag {
              display: inline-block;
              padding: 8px 14px;
              background: rgba(220, 220, 220, 0.6);
              color: $color1;
              font-size: 22px;
              line-height: 1.5;
              margin-left: 12px;
              border-radius: 6px;
              margin-top: 30px;
              text-align: left;
            }

            .active-tag {
              border: 1px solid $blur;
              background: $blur;
              color: #fff;
            }
          }
        }

        .type-list:last-child {
          margin-bottom: 0;
        }
      }

      .search-options {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .van-button {
          width: 45%;
          height: 75px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 28px;
          color: #fff;
          background: $blur;
          text-align: center;
          border-radius: 42px;
          border: 1px solid $blur;
        }

        .van-button:nth-child(1) {
          color: $blur;
          background: #fff;
          margin-right: 20px;
          border: 1px solid $blur;
        }
      }
    }
  }

  .pur-svg-boxers {
    cursor: pointer;
    padding: 26px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    box-shadow: 0 0 15px 0 #aaa;
    border-radius: 50%;
    position: fixed;
    bottom: 320px;
    right: 10px;
    z-index: 99;

    svg {
      width: 60px !important;
      height: 60px !important;
    }

    span {
      margin-top: 12px;
      font-size: 26px;
    }
  }

  .pur-svg-boxers:active {
    opacity: 0.7;
  }

  .pur-dialog {
    width: 520px;
    border-radius: 8px;
    background: #fff;
    padding: 40px 30px;

    .dialog-body {
      .dialog-title {
        color: $color1;
        text-align: center;
        font-size: 32px;
        line-height: 1.5;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .list-row {
        display: flex;
        align-items: center;
        line-height: 1.5;
        margin-bottom: 20px;

        span {
          font-size: 24px;
          color: $blur;
        }

        .label {
          display: inline-block;
          font-size: 26px;
          color: $color3;
        }
      }

      .footer-options {
        margin-top: 60px;
        display: flex;
        justify-content: center;

        span {
          width: 65%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          border-radius: 8px;
          padding: 16px 30px;
          color: #fff;
          background: $blur;
          font-size: 26px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
input {
  caret-color: #aaa;
}

.date-picker {
  .van-picker__cancel {
    font-size: 32px;
    font-weight: 500;
  }

  .van-picker__confirm {
    font-size: 32px;
    color: $blur;
    font-weight: 500;
  }
}
</style>
