// 葡萄牙语
let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  "appWebSocketList": {
    navTitle: 'Mensagem',
    IMErrorMessage: 'Falha na ligação de IM, clique para tentar novamente! ',
  },
  "appWebSocketDetails": {
    sendOption: 'Enviar',
    send: 'enviar',
    fileFail: 'Por favor, carregue e formate as imagens',
    IMportError: 'Atualmente não está ligado, por favor tente novamente! ',
    IMErrorMessage: 'Falha na ligação de IM, tente novamente! ',
    retryBtn: 'Tentar novamente',
  },
  "appLoginIndex": {
    navTitle: 'Login',
    projectName: 'Gerir loja',
    formLabel1: 'E-mail',
    formLabel2: 'password',
    placeholder: 'Por favor, insira',
    hint: "Se não tem conta",
    clickRegister: 'Clique para registar',
    forgetPass: 'esqueci-me da palavra-passe',
    optionBtn: 'Entrar',
    validateError: 'Por favor, complete a entrada! ',
  },
  "appForgetPassIndex": {
    navTitle: 'Esqueci-me da password',
    formLabel1: 'Caixa de correio de login',
    formLabel2: 'Código de verificação',
    formLabel3: 'Nova palavra-passe',
    formLabel4: 'Confirmar palavra-passe',
    formHint1: 'Por favor, introduza o seu e-mail de login',
    formHint2: 'Por favor, introduza uma nova palavra-passe',
    formHint3: 'Por favor, introduza o código de verificação',
    formHint4: 'Por favor, introduza a sua palavra-passe duas vezes',
    formOption: 'Enviar',
    sendOption: 'Contagem decrescente:',
    optionsBtn: 'Confirmar',
  },
  'footer': {
    home: "Loja",
    project: 'Produto',
    info: 'Mensagem',
    order: 'Ordem',
    user: 'Meu',
  },
  'setDrawer': {
    label1: 'Configurações multilingues',
    label2: 'Registo da loja',
    label3: 'Login da loja',
    label4: 'Atendimento ao Cliente',
    label5: 'Descarregar aplicação Android',
    label6: 'Descarregar aplicação iOS',
    optionsBtn: 'Cancelar',
  },
  'appHomeIndex': {
    accountLabel1: 'Vendas',
    accountLabel2: 'tráfego',
    cardLabel1: 'Visão geral dos dados',
    cardLabel2: 'Relatório de dados',
    cardLabel3: 'Visão geral do valor',
    ech1Title: 'Total de vendas',
    ech2Title: 'Número total de encomendas',
    ech3Title: 'Tráfego da loja',
    echBuoy1Title: 'Vendas totais',
    echBuoy2Title: 'Número total de encomendas',
    echBuoy3Title: 'Armazenar tráfego',
    dataCardTitle1: 'Vendas',
    dataCardTitle2: 'Tráfego',
    dataCardTitle3: 'Número total de encomendas',
    dataCardTitle4: 'Valor total pendente',
    dataCardDayTags: 'Hoje',
    dataYesterTags: "Dados de ontem",
    dataYesterTags1: "Quantidade de encomendas não verificadas",
    cardMenusLabel1: 'Imposto',
    cardMenusLabel2: 'Valor armazenado',
    cardMenusLabel3: 'Retirada',
    cardMenusLabel4: 'Partilhar convite',
    cardMenusLabel5: 'Lista de produtos',
    cardMenusLabel6: 'Lista de encomendas',
    cardMenusLabel7: 'Relatório de negócios',
    taxPopupTitle: 'Atualmente precisa de pagar impostos:',
    popupBtn: 'Vá e pague impostos',
    cardStateLabel1: 'Saldo',
    cardStateLabel2: 'Retirada',
    cardStateLabel3: 'Rendimento',
    cardStateLabel4: 'Equipa',
    cardSellHotTitle: 'Os 10 artigos mais vendidos',
    salesLabel: 'Vendas:',
  },
  'appSetLanguage': {
    navTitle: 'Definições multilingues',
    affirm: "confirmar",
  },
  "appServiceIndex": {
    navTitle: 'Atendimento ao Cliente',
    customerService: 'Atendimento exclusivo ao cliente',
    helloTitle: 'Olá',
    welcomeTitle: 'Eu sou teu<, podes contactar-me através dos seguintes canais',
    iconInformation: 'Selecione o software de comunicação para consulta',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
  },
  'appUserIndex': {
    blanceLabel: 'Saldo',
    blanceOption1: 'Retirar',
    blanceOption2: 'Armazenar valor',
    incomeLabel1: 'Rendimento acumulado',
    incomeLabel2: 'Rendimento da equipa',
    listLabel1: 'Armazenar informação',
    listLabel2: 'Definições do método de levantamento',
    listLabel3: 'Registo do Fundo',
    listLabel4: 'Relatório de negócios',
    listLabel5: 'Atividade de desconto',
    listLabel7: 'Empréstimo',
    listLabel8: 'Vá ao shopping',
    listLabel9: 'Descarregar aplicação',
    listLabelLast: 'Sair',
  },
  'downloadAppIndex': {
    navTitle: 'Download',
    downloadAndroidApp: "Download Android",
    downloadIosApp: "Download do IOS",
  },
  "appDiscountActivity": {
    navTitle: 'Atividade de desconto',
    popupLabel1: 'Valor',
    popupPlace1: 'Por favor, introduza o valor',
    popupLabel2: 'Desconto',
    popupPlace2: 'Por favor, introduza o desconto',
    popupOption: 'Confirmar',
    listLabel1: 'Quantidade necessária:',
    listLabel2: 'Taxa de desconto:',
    listLabel3: 'Tempo de desconto:',
    dialogTitle: "Confirme se pretende eliminar!",
    dialogAffirm: 'Confirmar',
    dialogClose: "Cancelar",
  },
  "appInviteIndex": {
    navTitle: 'Convidar novas atividades',
    headerTitle: 'Convide amigos para ganhar recompensas',
    copyOption: 'copiar',
    copySuccess: 'Cópia bem-sucedida',
    inviteTitle: 'Número total de convites',
    inviteLabel1: 'Número de pessoas convidadas:',
    inviteLabel2: 'Comissão total:',
    ruleTitle: 'Regra de convite',
    ruleHint1: ' 1. Quem convida pode convidar amigos para completar o registo partilhando o link da promoção, sendo que os amigos convidados serão associados a si;',
    ruleHint2: ' 2.º Depois de convidar os utilizadores a concluir o pagamento, poderá receber uma parte dos lucros;',
    ruleHint3: ' 3.º Apenas são suportadas recompensas de convite de terceiro nível e a proporção de recompensas é: <%, >%, @%; ',
  },
  "appInviteUserRecordIndex": {
    navTitle: 'Convidar registo de utilizador',
    recordTitle1: 'Número total de utilizadores',
    recordTitle2: 'Nível 1',
    recordTitle3: 'Nível 2',
    recordTitle4: 'Nível 3',
    tabLabel1: 'Todos',
    tabLabel2: 'Nível 1',
    tabLabel3: 'Segundo nível',
    tabLabel4: 'Nível 3',
    listLabel1: 'Nível:',
    listLabel2: 'Lucro:',
  },
  "appInviteIncomeRecordIndex": {
    navTitle: 'Registo de receita de convite',
    recordTitle1: 'Receita total',
    recordTitle2: 'Nível 1',
    recordTitle3: 'Nível 2',
    recordTitle4: 'Nível 3',
    tabLabel1: 'Todos',
    tabLabel2: 'Nível 1',
    tabLabel3: 'Segundo nível',
    tabLabel4: 'Nível 3',
    listLabel1: 'Lucro:',
    listLabel2: 'Proporção:',
  },
  "appWithdrawIndex": {
    navTitle: 'Retirada',
    currencyType1: 'Moeda fiduciária',
    currencyType2: 'Moeda virtual',
    formLabel1: 'Tipo de moeda:',
    formPlace1: 'Por favor, selecione o tipo de moeda',
    formLabel2: 'Selecionar moeda:',
    formPlace2: 'Por favor, selecione o tipo de moeda',
    formLabel3: 'Valor da retirada:',
    formPlace3: 'Por favor, introduza o valor do levantamento',
    formLabel4: 'Password de levantamento:',
    formPlace4: 'Por favor, introduza a palavra-passe de levantamento:',
    balanceLabel: 'Saldo atual:',
    amountLabel: 'Chegada real:',
    optionsBtn: 'Enviar',
    dialogClose: 'Cancelar',
    dialogAffirm: 'Confirmar',
    currencySelectError: 'Por favor, selecione o tipo de moeda',
    currencyError: 'Por favor, selecione a moeda',
    affirmDialogDialogTitle1: 'Não definiu uma palavra-passe de pagamento. Quer configurá-lo? ',
    affirmDialogDialogTitle2: 'Não configurou o canal de retirada, deseja defini-lo? ',
    affirmDialogBtn2: 'Cancelar',
    affirmDialogBtn1: 'Confirmar',
  },
  'appSetWithdrawalIndex': {
    navTitle: 'Configuração do método de levantamento',
    currencyType1: 'Moeda fiduciária',
    currencyType2: 'Moeda virtual',
    formLabel1: 'Tipo de moeda:',
    formPlace1: 'Por favor, selecione o tipo de moeda',
    formLabel2: 'Selecionar moeda:',
    formPlace2: 'Por favor, selecione o tipo de moeda',
    addressLabel: 'Morada:',
    addressPlace: 'Por favor, introduza o endereço da moeda virtual',
    hintLabel: 'Dica importante',
    hintValue: 'Transferir apenas ¥ ativos',
    optionsBtn: 'Enviar',
    dialogClose: 'Cancelar',
    dialogAffirm: 'Confirmar',
    currencySelectError: 'Por favor, selecione o tipo de moeda',
    currencyError: 'Por favor, selecione a moeda',
    currencyFail: 'Ainda não aberto',
  },
  //  提现设置-法定货币-BRL
  'brlLanguage': {
    formLabel1: 'nome real',
    formPlace1: 'Por favor, introduza o seu nome verdadeiro',
    formLabel2: 'E-mail',
    formPlace2: 'Por favor, introduza o seu e-mail',
    formLabel3: 'Número de telemóvel',
    formPlace3: 'Por favor, introduza o número do seu telemóvel',
    formLabel4: 'tipo PIX',
    formPlace4: 'Selecionar o tipo de PIX',
    formLabel5: 'Conta PIX',
    formPlace5: 'Introduza o número da sua conta PIX',
  },
  //  提现设置-法定货币-EUR
  'eurLanguage': {
    formLabel1: 'País/Região',
    formPlace1: 'Por favor, introduza o país/região',
    formLabel2: 'nome',
    formPlace2: 'Por favor, introduza um nome',
    formLabel3: 'Apelido',
    formPlace3: 'Por favor, introduza o seu apelido',
    formLabel4: 'rápido',
    formPlace4: 'Por favor, introduza rápido',
    formLabel5: 'Nome do banco',
    formPlace5: 'Por favor, introduza o nome do banco',
    formLabel6: 'iban',
    formPlace6: 'Por favor, introduza iban',
    formLabel7: 'Número de telemóvel',
    formPlace7: 'Por favor, introduza o número do seu telemóvel',
  },
  //  提现设置-法定货币-USD
  'usdLanguage': {
    formLabel1: 'País/Região',
    formPlace1: 'Por favor, introduza o país/região',
    formLabel2: 'nome',
    formPlace2: 'Por favor, introduza um nome',
    formLabel3: 'Apelido',
    formPlace3: 'Por favor, introduza o seu apelido',
    formLabel4: 'rápido',
    formPlace4: 'Por favor, introduza rápido',
    formLabel5: 'ach',
    formPlace5: 'Por favor, introduza ach',
    formLabel6: 'Nome do banco',
    formPlace6: 'Por favor, introduza o nome do banco',
    formLabel7: 'Conta Bancária',
    formPlace7: 'Por favor, introduza a conta bancária',
    formLabel8: 'Província ou estado',
    formPlace8: 'Por favor, introduza a província ou o estado',
    formLabel9: 'Número de telemóvel',
    formPlace9: 'Por favor, introduza o número do seu telemóvel',
  },
  //  提现设置-法定货币-GBP
  'gbpLanguage': {
    formLabel1: 'País/Região',
    formPlace1: 'Por favor, introduza o país/região',
    formLabel2: 'nome',
    formPlace2: 'Por favor, introduza um nome',
    formLabel3: 'Apelido',
    formPlace3: 'Por favor, introduza o seu apelido',
    formLabel4: 'Código do banco',
    formPlace4: 'Por favor, introduza o código do banco',
    formLabel5: 'Nome do banco',
    formPlace5: 'Por favor, introduza o nome do banco',
    formLabel6: 'Conta Bancária',
    formPlace6: 'Por favor, introduza a conta bancária',
    formLabel7: 'Número de telemóvel',
    formPlace7: 'Por favor, introduza o número do seu telemóvel',
  },
  //  提现设置-法定货币-JPY
  'jpyLanguage': {
    formLabel1: 'País/Região',
    formPlace1: 'Por favor, introduza o país/região',
    formLabel2: 'nome',
    formPlace2: 'Por favor, introduza um nome',
    formLabel3: 'Apelido',
    formPlace3: 'Por favor, introduza o seu apelido',
    formLabel4: 'zengin',
    formPlace4: 'Por favor, introduza zengin',
    formLabel5: 'Nome do banco',
    formPlace5: 'Por favor, introduza o nome do banco',
    formLabel6: 'Conta Bancária',
    formPlace6: 'Por favor, introduza a conta bancária',
    formLabel7: 'Província ou estado',
    formPlace7: 'Por favor, introduza a província ou o estado',
    formLabel8: 'Número de telemóvel',
    formPlace8: 'Por favor, introduza o número do seu telemóvel',
  },
  //  提现设置-法定货币-AUD
  'audLanguage': {
    formLabel1: 'País/Região',
    formPlace1: 'Por favor, introduza o país/região',
    formLabel2: 'nome',
    formPlace2: 'Por favor, introduza um nome',
    formLabel3: 'Apelido',
    formPlace3: 'Por favor, introduza o seu apelido',
    formLabel4: 'BSB',
    formPlace4: 'Por favor, introduza BSB',
    formLabel5: 'Nome do banco',
    formPlace5: 'Por favor, introduza o nome do banco',
    formLabel6: 'Conta Bancária',
    formPlace6: 'Por favor, introduza a conta bancária',
    formLabel7: 'Província ou estado',
    formPlace7: 'Por favor, introduza a província ou o estado',
    formLabel8: 'Número de telemóvel',
    formPlace8: 'Por favor, introduza o número do seu telemóvel',
  },
  //  提现设置-法定货币-krw
  'krwLanguage': {
    formLabel1: 'nome',
    formPlace1: 'Por favor, introduza um nome',
    formLabel2: 'Apelido',
    formPlace2: 'Por favor, introduza o seu apelido',
    formLabel3: 'Nome do banco',
    formPlace3: 'Por favor, introduza o nome do banco',
    formLabel4: 'Conta Bancária',
    formPlace4: 'Por favor, introduza a conta bancária',
    formLabel5: 'Número de telemóvel',
    formPlace5: 'Por favor, introduza o número do seu telemóvel',
  }
  ,
  //  提现设置-法定货币-mxn
  'mxnLanguage': {
    formLabel1: 'Nome do banco',
    formPlace1: 'Por favor, introduza o nome do banco',
    formLabel2: 'Conta',
    formPlace2: 'Por favor, introduza a sua conta',
    formLabel3: 'conta clabe',
    formPlace3: 'Por favor, introduza a sua conta clabe',
    formLabel4: 'Número de telemóvel',
    formPlace4: 'Por favor, introduza o número do seu telemóvel',
  },
  'appStatisticsReportIndex': {
    navTitle: 'Relatório Operacional',
    cardLabel1: 'Total de vendas',
    cardLabel2: 'Valor total da compra',
    cardLabel3: 'Lucro total',
    cardLabel4: 'Número total de encomendas',
    listLabel1: 'Hora:',
    listLabel2: 'Pedido:',
    listLabel3: 'Vendas:',
    listLabel4: 'Valor da compra:',
    profitLabel: 'lucro',
    searchDateTitle: 'Hora do documento',
    searchIntervalTitle: 'Tempo de intervalo',
    searchStartTimePlace: 'Hora de início',
    searchEndTimePlace: 'Hora de fim',
    searchOptions1: 'Repor',
    searchOptions2: 'Confirmar',
    searchDateAffirm: 'confirmar',
    searchDateClose: 'Cancelar',
    searchIntervalLabel1: 'Todos',
    searchIntervalLabel2: 'Hoje',
    searchIntervalLabel3: 'Ontem',
    searchIntervalLabel4: 'Esta semana',
    searchIntervalLabel5: 'Este mês',
    searchIntervalLabel6: 'Este ano',
    searchIntervalOptions: 'Cancelar',
  },
  "appAmountRechargeIndex": {
    navTitle: 'Valor armazenado',
    currencyType1: 'Moeda fiduciária',
    currencyType2: 'Moeda virtual',
    formLabel1: 'Tipo de moeda:',
    formPlace1: 'Por favor, selecione o tipo de moeda',
    formLabel2: 'Selecionar moeda:',
    formPlace2: 'Por favor, selecione o tipo de moeda',
    formLabel3: 'Valor do valor armazenado:',
    formPlace3: 'Por favor, introduza o valor armazenado',
    formLabel4: 'Método de recarga',
    formPlace4: 'Por favor, selecione o método de armazenamento:',
    balanceLabel: 'Saldo atual:',
    amountLabel: 'Chegada real:',
    optionsBtn: 'Enviar',
    dialogClose: 'Cancelar',
    dialogAffirm: 'Confirmar',
    currencySelectError: 'Por favor, selecione o tipo de moeda',
    currencyError: 'Por favor, selecione a moeda',
    successCopy: 'Copiar com sucesso',
    hintLabel: "Lembrete importante:",
    hintValue: 'Apenas é permitida a transferência de ativos em ¥',
    legalHint: 'Entre em contato com o atendimento ao cliente!'
  },
  'appShopInfoIndex': {
    navTitle: 'Armazenar informação',
    navEditText: 'Editar',
    formLabel1: 'Armazenar imagem',
    formLabel2: 'nome da loja',
    formLabel3: 'E-mail de login',
    formLabel4: 'código de convite',
    formLabel5: 'Password de login',
    formLabel6: 'Password de pagamento',
    formLabel7: 'Mensagem de descrição',
    formLabel8: 'Guardar imagem de fundo',
    formLabel9: 'Imagem de fundo do mercado',
    formLabel10: 'Informações do certificado',
    formHint1: 'Frente do documento',
    formHint2: 'O verso do certificado',
    fileFail: 'Por favor, carregue fotos e formate',
    optionsBtn: 'Enviar',
  },
  'appShopInfoEdit': {
    navTitle: 'Editor de informações da loja',
    formLabel1: 'Armazenar imagem',
    formLabel2: 'nome da loja',
    formLabel3: 'E-mail de login',
    formLabel4: 'código de convite',
    formLabel7: 'Mensagem de descrição',
    formLabel8: 'Guardar imagem de fundo',
    formLabel9: 'Imagem de fundo do mercado',
    fileFail: 'Por favor, carregue fotos e formate',
    optionsBtn: 'Enviar',
  },
  'appChangeLoginPass': {
    navTitle: 'Alterar password de login',
    formLabel1: 'Password antiga',
    formLabel2: 'Nova palavra-passe',
    formLabel3: 'Código de verificação',
    formHint1: 'Por favor, introduza a palavra-passe antiga',
    formHint2: 'Por favor, introduza uma nova palavra-passe',
    formHint3: 'Por favor, introduza o código de verificação',
    formOption: 'Enviar',
    sendOption: 'Contagem decrescente:',
    optionsBtn: 'Confirmar',
  },
  'appChangePayPass': {
    navTitle: 'Alterar palavra-passe de pagamento',
    formLabel1: 'Password de pagamento',
    formLabel2: 'Código de verificação',
    formHint1: 'Por favor, introduza a palavra-passe de pagamento',
    formHint2: 'Por favor, introduza o código de verificação',
    formOption: 'Enviar',
    sendOption: 'Contagem decrescente:',
    optionsBtn: 'Confirmar',
  },
  "appFundingRecordsIndex": {
    navTitle: 'Registo do Fundo',
    searchRecordsTitle: 'Tipo de fundo',
    searchDateTitle: 'Seleção de tempo',
    searchFundingLabel1: 'Todos os fundos',
    searchFundingLabel2: 'Tipo de depósito',
    searchFundingLabel3: 'Registo de levantamento',
    searchOptions1: 'Repor',
    searchOptions2: 'Confirmar',
    searchDateAffirm: 'confirmar',
    searchDateClose: 'Cancelar',
    stareTimePlace: 'Hora de início',
    endTimePlace: 'Hora de fim',
    typeAll: 'Todos',
  },
  "appProjectIndex": {
    navTitle: 'Lista de produtos',
    searchPlace: 'Por favor, introduza o nome do produto que pretende pesquisar',
    shelvesLabel: 'prateleiras superiores e inferiores',
    brandPlace: 'Por favor, selecione uma marca',
    classPlace: 'Por favor, selecione uma categoria',
    searchOptions1: "Repor",
    searchOptions2: 'Confirmar',
    shelvesTag1: "Todos",
    shelvesTag2: "nas prateleiras",
    shelvesTag3: "fora das prateleiras",
    salesLabel: 'Vendas:',
    dialogAffirm: 'Confirmar',
    dialogClose: 'Cancelar',
    listLabel: 'Prateleiras para cima e para baixo:',
  },
  "appProjectLibraryIndex": {
    navTitle: "Biblioteca de Produtos",
    searchPlace: 'Por favor, introduza o nome do produto que pretende pesquisar',
    brandPlace: 'Por favor, selecione uma marca',
    classPlace: 'Por favor, selecione uma categoria',
    searchOptions1: "Repor",
    searchOptions2: 'Confirmar',
    salesLabel: 'Vendas:',
    dialogAffirm: 'Confirmar',
    dialogClose: 'Cancelar',
    productPackageLabel1: 'Product Package',
    productPackageLabel2: '(Quickly Add Product)',

    selectAll: 'Múltipla Escolha',
    selectHint: "Suporta apenas até 50 produtos",
    submitOptions: "Enviar",
    dialogHint: 'Você tem certeza de listar este produto em lote?',

  },
  'appProductPackageIndex': {
    navTitle: "Product Package",
    listLabel1: "Recommended star rating:",
    listLabel2: 'Synchronization status:',
    listLabel3: "Quantidade do produto:",
    optionsBtn: "Sincronização",
    dialogHint: 'Tem certeza de que deseja usar este pacote de produto?',
    dialogOptions1: "Cancelar",
    dialogOptions2: "Confirmar",
    messageToast: 'Envio bem sucedido, sincronização de informações do produto em andamento...',
  },
  'appProductPackageList': {
    navTitle: "Product Package Details",
    salesLabel: "Vendas:",
  },
  "appProductFirstIndex": {
    navTitle: 'Informações do produto',
    nextStep: 'Próximo passo',
    label1: 'Nome do produto:',
    label2: 'Marca do produto:',
    label3: 'Categoria do produto:',
    label4: 'Preço mínimo de compra:',
    label5: 'Preço mínimo de venda:',
    label6: 'Preço mínimo de venda:',
    label7: 'Imagem da capa:',
    label8: 'Imagem do produto:',

  },
  'appProductNextStepIndex': {
    navTitle: 'Especificações do produto',
    previous: 'etapa anterior',
    navBtn: 'Enviar',
    label1: 'Preço de compra:',
    placeHint: 'Por favor, introduza',
    label2: 'Preço:',
    label3: 'Preço de mercado:',
    specLabel: 'Atributo de especificação:',
    optionBtn: "Confirmar",
    validateMessage1: 'O preço original não pode ser inferior ao preço de compra',
    validateMessage2: "O preço original não pode ser inferior ao preço de venda atual",
    validateMessage3: "O preço de venda não pode ser inferior ao preço de compra",
    validateMessage4: "O preço de venda não pode ser superior ao preço original",
    shelvesTitle: 'Deseja carregar ou descarregar as prateleiras? ',
    shelvesOptions1: 'Não',
    shelvesOptions2: 'Sim',
  },
  "appOrderIndex": {
    tagsLabel1: 'Todos',
    orderTotalNumber: 'Número total do pedido',
    listLabel1: 'Data do pedido:',
    listLabel2: 'Total de vendas:',
    listLabel3: 'Quantidade do produto:',
    listLabel4: 'Estado do pedido:',
    listLabel5: 'Estado do pagamento:',
    listHint: 'Compra',
    searchOptionBtn: 'Pesquisar',
    searchPlace: 'Por favor, introduza o conteúdo do inquérito',
    searchDateStartPlace: 'Hora de início',
    searchDateEndPlace: 'Hora de fim',
    searchDateAffirm: 'confirmar',
    searchDateClose: 'Cancelar',
    searchTypePayTitle: 'Estado do pagamento',
    payTags1: 'Todos',
    payTags2: 'Pagamento pendente',
    payTags3: 'Pago',
    searchTypeOrderTitle: 'Estado do pedido',
    searchOptions1: 'Repor',
    searchOptions2: 'Confirmar',
    purDialogTitle: 'Comprar tudo',
    purDialogLabel1: 'Valor da compra:',
    purDialogLabel2: 'Valor de vendas:',
    purDialogLabel3: 'Valor do lucro:',
    purOptions: "Confirmar",
    purRowTitle: 'Compra',
    purRowLabel1: 'Valor da compra:',
    purRowLabel2: 'Valor de vendas:',
    purRowLabel3: 'Valor do lucro:',
  },
  "appOrderDetailIndex": {
    navTitle: 'Detalhes do pedido',
    detailInfoLabel1: 'Número de encomenda:',
    detailInfoLabel2: 'Prazo do pedido:',
    detailInfoLabel3: 'Método de pagamento:',
    detailInfoLabel4: 'Estado do pedido:',
    detailInfoLabel5: 'Estado do pagamento:',
    productLabel1: 'Informações do produto',
    productAttributesLabel: 'Atributos do produto:',
    productAccountLabel1: 'Vendas:',
    productAccountLabel2: 'Valor da compra:',
    countInfoLabel1: 'Total de vendas:',
    countInfoLabel2: 'Valor total da compra:',
    countInfoLabel3: 'Lucro total:',
    countInfoLabel4: 'Quantidade total:',
    countInfoLabel5: 'Taxa de desconto:',
  },
  "appOrderDetailLogistics": {
    navTitle: 'Logística de encomendas',
    logisticsLabel: 'Encomenda',
    listLabel1: "Nome:",
    listLabel2: "Telefone:",
    listLabel3: "Código postal:",
    listLabel4: "Endereço:",
  },
  "appOrderDetailRecord": {
    navTitle: 'Registo de encomenda',
    logisticsLabel: 'Encomenda',
    recordEnumLabel1: 'Cliente cria encomenda',
    recordEnumLabel2: 'Ordem de pagamento do cliente',
    recordEnumLabel3: 'Pedido de compra da loja',
    recordEnumLabel4: 'O pedido começa a ser enviado',
    recordEnumLabel5: 'Utilizador confirma receção',
    recordEnumLabel6: 'Chega o reembolso do sistema',
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: 'Atualização de versão, confirma? ',
    loadingTitle: 'A descarregar...',
    systemUpgrade: 'Atualização do sistema',
    upgradeImmediately: 'Confirmar',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "Carregando",
    finishedText: "Nenhum",
    loadingText: "Carregando",
    nullText: 'Ainda não há dados',
    loadingFinished: "Carregamento concluído",
    listNullText: 'Não mais',
    pullIngText: 'Puxe para baixo para atualizar',
    loosingText: 'Libertar para atualizar',
    pullingSuccess: 'Carregamento suspenso bem-sucedido',
    webSocketSuccess: 'Tem uma nova mensagem',
    imLinkSuccess: 'Ligação do serviço de IM bem-sucedida! ',
    imNonsupport: 'Este browser não suporta websocket',
    imLinkLoading: 'IM está a ligar, aguarde...',
    imLinkErrorLoading: 'Falha na ligação de IM, atualize a página ou contacte o programador! ',
    dialogHint: 'Você já tem uma conta?',
    dialogClose: 'Registar',
    dialogAffirm: "Login",
  },
};
export default obj;
